import React, { type ComponentProps, useMemo } from 'react';

import {
  Select,
  SELECT_PAGE_SIZE_BIG,
  useSelectAsync,
} from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import { getFullName } from '@npm/core/ui/utils/formatters';
import {
  type RoleType,
  CbRoleType,
  CbSubjectType,
  useBrokerageFirmUserIndexInfinite,
} from '@npm/data-access';

import { useCurrentRole } from '../../../auth/user/role/hooks/useCurrentRole';

type Props = ComponentProps<typeof Select> & {
  roleType?: RoleType[];
};

const defaultRoleType = [CbRoleType.items.BROKERAGE_ADMIN];

export const BrokerageUserSearch = ({
  roleType = defaultRoleType,
  variant = 'search',
  ...props
}: Props) => {
  const role = useCurrentRole();

  const brokerageFirmId =
    (role?.subject_type?.code === CbSubjectType.items.BROKERAGE_FIRM ||
      // there is bug on BE returning wrong code
      role?.subject_type?.code === 'BrokerageFirm') &&
    role?.subject?.id;

  const [{ searchTerm }, selectAsyncProps] = useSelectAsync();

  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useBrokerageFirmUserIndexInfinite(
      {
        brokerageFirmId,
        roleType,
        ...(searchTerm ? { search: searchTerm } : {}),
        size: SELECT_PAGE_SIZE_BIG,
      },
      {
        queryConfig: { enabled: !!brokerageFirmId },
        onError: Select.onError,
      }
    );

  const options = useMemo(() => {
    if (!data) return null;

    const mergedUsers = data.pages.reduce((mergedArray, page) => {
      return mergedArray.concat(page.users);
    }, []);

    return mergedUsers.map(({ id, person }) => ({
      label: getFullName(person.first, person.last, person.middle),
      value: id,
    }));
  }, [data]);

  const placeholderLabel = variant === 'search' ? 'All' : 'Select';

  const placeholderWithCount = options
    ? `${placeholderLabel} (${data?.pages[0]?.pagination?.total_records ?? 0})`
    : placeholderLabel;

  return (
    <Select
      variant={variant}
      showSearch
      onPopupScroll={e =>
        onPopupScroll(e, hasNextPage && !isFetchingNextPage && fetchNextPage)
      }
      infiniteLoading={isFetchingNextPage}
      loading={isLoading}
      placeholder={placeholderWithCount}
      options={options}
      value={options?.length ? props.value : null}
      {...props}
      {...selectAsyncProps}
    />
  );
};
