import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { CypressDataIds } from '@npm/core/ui/constants';
import { composeDataId } from '@npm/core/ui/utils/cypress';
import { type Holding } from '@npm/data-access';

import { ButtonsContainer } from './HoldingCard.styles';

type Props = {
  holding: Holding;
  disabledTooltip?: string;
  onDeleteClick?: () => void;
  onEditClick?: () => void;
  onEnterIOIClick?: () => void;
  dense?: boolean;
  deleteButtonName?: string;
  deleteButtonForcelyShow?: boolean;
};

export const ActionButtons = ({
  holding,
  onEditClick,
  onDeleteClick,
  onEnterIOIClick,
  disabledTooltip,
  dense = !!onEnterIOIClick,
  deleteButtonName = 'Cancel',
  deleteButtonForcelyShow,
}: Props) => {
  const showDeleteButton =
    !holding?.order_item_count || deleteButtonForcelyShow;

  return (
    <ButtonsContainer>
      {!!onEnterIOIClick && (
        <Button
          variant="outline"
          onClick={() => onEnterIOIClick()}
          color="error"
          style={{ flexGrow: 1 }}
        >
          Sell
        </Button>
      )}
      {showDeleteButton && !!onDeleteClick && (
        <Tooltip title={disabledTooltip}>
          <Button
            variant="text"
            color="error"
            icon={<Icon name="trash" />}
            data-cy={composeDataId(
              CypressDataIds.Holdings.Button.Delete,
              holding?.id
            )}
            onClick={() => onDeleteClick()}
            disabled={!!disabledTooltip}
            {...(dense && {
              style: { flexGrow: 0, flexBasis: 40 },
            })}
          >
            {!dense && deleteButtonName}
          </Button>
        </Tooltip>
      )}
      {!!onEditClick && (
        <Tooltip title={disabledTooltip}>
          <Button
            variant="outline"
            data-cy={composeDataId(
              CypressDataIds.Holdings.Button.Edit,
              holding?.id
            )}
            onClick={() => onEditClick()}
            disabled={!!disabledTooltip}
            {...(dense && {
              icon: <Icon name="edit" />,
              variant: 'text',
              style: { flexGrow: 0, flexBasis: 40 },
            })}
          >
            {!dense && 'Edit'}
          </Button>
        </Tooltip>
      )}
    </ButtonsContainer>
  );
};
