import { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { eventBus } from '@npm/core/ui/utils/eventBus';

import { type ZendeskTicketGroupID } from '../widget';

export type WithSupportTicketType = {
  zendeskTicketGroupId: ZendeskTicketGroupID;
};

type Props = WithSupportTicketType & ComponentProps<typeof Text>;

export const ZendeskContactButton = ({
  zendeskTicketGroupId,
  size = 'sm',
  ...textProps
}: Props) => {
  return (
    <Text
      size={size}
      {...textProps}
      onClick={() => {
        eventBus.dispatch('REQUEST_SUPPORT', { key: zendeskTicketGroupId });
      }}
    />
  );
};
