import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { type DrawerProps, Space } from 'antd';

import { useMountedState } from '@npm/utils';

import { CypressDataIds } from '../../../constants';
import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { truncate } from '../../../utils/formatters';
import { useHandleApplePencilTouch } from '../../../utils/handleAppleTouch';
import { Alert } from '../../atoms/Alert';
import { Button } from '../../atoms/Button';
import { Flex } from '../../atoms/common';
import { ErrorSkeleton } from '../../atoms/ErrorSkeleton';
import { Icon } from '../../atoms/Icon';
import { Skeleton } from '../../atoms/Skeleton';
import { Heading } from '../../atoms/Typography';
import { AlertContainer } from '../../molecules/AlertContainer';
import { useLayoutConfig } from '../Layout';

import { useDrawerMaskScrollPropagation } from './Drawer.hooks';
import { DrawerBreadcrumbs } from './DrawerBreadcrumbs';
import { ScrollIndicator } from './ScrollIndicator';

import * as S from './Drawer.styles';
import {
  FLOATING_BUTTON_HEIGHT,
  FLOATING_BUTTON_HEIGHT_MOBILE,
} from './Drawer.styles';

type Props = DrawerProps & {
  onSubmit?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClose?: (
    e?: React.MouseEvent | React.KeyboardEvent,
    trigger?: 'mask' | 'button'
  ) => void;
  // TODO: group into buttonProps and refactor
  buttonText?: string;
  buttonLoading?: boolean;
  buttonDataCy?: string;
  isFooterFixed?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  footerHeight?: number;
  icon?: React.ReactNode;
  id?: string;
  alertContainerId?: string;
  noHeader?: boolean;
  buttonIcon?: React.ReactNode;
  titleHeadingProps?: Partial<React.ComponentProps<typeof Heading>>;
  backToRoute?: string; // this will be used as a title for the drawer breadcrumb
  isFullHeight?: boolean;
  extraHeaderContent?: React.ReactNode;
};

const DRAWER_MAX_WIDTH = 440;
export const DRAWER_CONTENT_ID = 'drawer-content';
export const DRAWER_HEADER_CLASSNAME = 'drawer-header';

export const Drawer = ({
  onClose,
  title,
  titleHeadingProps,
  children,
  onSubmit,
  isLoading,
  isDisabled,
  buttonText,
  buttonLoading,
  buttonDataCy = CypressDataIds.Drawer.SubmitButton,
  buttonIcon,
  footer,
  footerHeight,
  icon,
  id,
  alertContainerId,
  className,
  noHeader,
  getContainer,
  isFullHeight,
  backToRoute,
  isFooterFixed,
  extraHeaderContent,
  ...restProps
}: Props) => {
  const getMountedState = useMountedState();

  const layoutConfig = useLayoutConfig();
  const handleApplePencil = useHandleApplePencilTouch(onClose);
  const { isMobile } = useBreakpoints();
  const fullHeight = isFullHeight != null ? isFullHeight : isMobile;

  const [hasError, setHasError] = useState(false);

  const hasFooter = !!(footer || onSubmit);
  const renderFooter = () => {
    if (isLoading) return null;
    if (footer)
      return (
        <S.FooterWrapper
          $hasPadding={false}
          $height={footerHeight}
          $isFixed={isFooterFixed}
        >
          {footer}
        </S.FooterWrapper>
      );
    if (onSubmit)
      return (
        <DrawerButton
          buttonDataCy={buttonDataCy}
          onSubmit={onSubmit}
          isDisabled={isDisabled || hasError}
          buttonText={buttonText}
          title={title}
          buttonLoading={buttonLoading}
          buttonIcon={buttonIcon}
          isFooterFixed={isFooterFixed}
        />
      );
    return null;
  };

  const bottomOffset =
    hasFooter && (footerHeight || isFooterFixed || !isMobile)
      ? footerHeight ??
        (isMobile ? FLOATING_BUTTON_HEIGHT_MOBILE : FLOATING_BUTTON_HEIGHT)
      : 0;

  const handleClose = e => {
    const drawerMask = document.querySelector('.ant-drawer-mask');

    if (drawerMask) {
      drawerMask.setAttribute('data-dd-action-name', 'Close Drawer');
    }

    onClose?.(e, 'mask');
  };

  // Propagate scroll events to the underlying scrollable element
  // so that the antd drawer mask doesn't swallow them
  useDrawerMaskScrollPropagation();

  return (
    <S.Drawer
      className={className}
      width={isMobile ? '100%' : DRAWER_MAX_WIDTH}
      destroyOnClose
      mask={true}
      maskClosable={!isMobile}
      onClose={handleClose}
      getContainer={
        getContainer !== undefined ? getContainer : isMobile ? 'body' : false
      }
      $layoutConfig={layoutConfig}
      $isFullHeight={fullHeight}
      data-dd-action-name="Drawer"
      {...restProps}
    >
      <>
        <S.Wrapper
          id={id}
          $footerHeight={footerHeight}
          data-cy={restProps['data-cy']}
        >
          {!noHeader && (
            <S.HeaderWrap className={DRAWER_HEADER_CLASSNAME}>
              <S.HeaderButton
                onClick={e => onClose?.(e, 'button')}
                data-cy={CypressDataIds.Drawer.CloseButton}
                {...handleApplePencil}
              >
                {icon ? icon : <Icon name="chevron-left" size="xs" />}
              </S.HeaderButton>

              {title && (
                <Heading
                  style={{ width: '100%' }}
                  variant="h2"
                  as="div"
                  {...titleHeadingProps}
                >
                  <Flex gap="sm" style={{ width: '100%' }}>
                    <DrawerBreadcrumbs
                      title={backToRoute}
                      onBreadcrumbClick={() => onClose?.(undefined, 'button')}
                    />
                    {typeof title === 'string'
                      ? truncate(
                          title,
                          backToRoute ? 50 : 65,
                          '...',
                          backToRoute ? 35 : 50
                        )
                      : title}
                  </Flex>
                </Heading>
              )}

              {extraHeaderContent}
            </S.HeaderWrap>
          )}
          <S.Content
            id={DRAWER_CONTENT_ID}
            className={DRAWER_CONTENT_ID}
            $bottomPadding={bottomOffset}
          >
            <AlertContainer id={alertContainerId} />
            {isLoading || (isLoading != null && !getMountedState()) ? (
              <Skeleton.Base />
            ) : (
              <ErrorBoundary
                FallbackComponent={ErrorSkeleton}
                onError={() => setHasError(true)}
                onReset={() => setHasError(false)}
              >
                {children}
              </ErrorBoundary>
            )}
            <ScrollIndicator bottomOffset={bottomOffset} />
          </S.Content>
        </S.Wrapper>
        {renderFooter()}
      </>
    </S.Drawer>
  );
};

export const DrawerButton = ({
  onSubmit,
  isDisabled,
  buttonText,
  buttonLoading,
  title,
  buttonDataCy,
  buttonIcon,
  isFooterFixed,
}: Pick<
  Props,
  | 'onSubmit'
  | 'isDisabled'
  | 'buttonText'
  | 'title'
  | 'buttonDataCy'
  | 'buttonLoading'
  | 'buttonIcon'
  | 'isFooterFixed'
>) => {
  const handleApplePencil = useHandleApplePencilTouch(onSubmit);

  return (
    <S.FooterWrapper $isFixed={isFooterFixed}>
      <Button
        onClick={onSubmit}
        disabled={isDisabled}
        block
        loading={buttonLoading}
        data-cy={buttonDataCy}
        icon={buttonIcon}
        {...handleApplePencil}
      >
        {buttonText || 'Update ' + title}
      </Button>
    </S.FooterWrapper>
  );
};
