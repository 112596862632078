import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { ErrorSkeleton } from '../../../atoms/ErrorSkeleton';
import { useLayoutStore } from '../../Layout/Layout.store';
import { Disclosure } from '../Disclosure';

import {
  WIZARD_CONTENT_WRAPPER_ID,
  WIZARD_SIDEBAR_ID,
  WIZARD_WRAPPER_ID,
} from './WizardLayout.constants';

import * as S from './WizardLayout.styles';

type Props<T> = {
  progressBarShown: boolean;
  body?: React.ReactNode;
  headerContent?: React.ReactNode;
  afterBody?: React.ReactNode;
  sidebarContent?: React.ReactNode;
  sidebarOpen?: boolean;
  toggleSidebarOpen?: () => void;
  expandablePanel?: React.ReactNode | null;
  outlined?: boolean;
  backgroundColor?: string;
  noPadding?: boolean;
  hasHeader?: boolean;
};

export const WizardLayout = <T,>({
  progressBarShown,
  headerContent,
  body,
  afterBody,
  sidebarContent,
  sidebarOpen,
  expandablePanel = <Disclosure />,
  outlined = false,
  backgroundColor,
  noPadding,
  hasHeader,
}: Props<T>) => {
  const { isMobile, isTablet } = useBreakpoints();
  const isDesktop = !isMobile && !isTablet;

  const maxWidth = useLayoutStore(store => store.maxWidth);

  const renderMobileContentWithSidebar = () => {
    if (sidebarOpen) {
      return <S.Sidebar id={WIZARD_SIDEBAR_ID}>{sidebarContent}</S.Sidebar>;
    } else {
      return <S.Content $outlined={outlined}>{body}</S.Content>;
    }
  };

  // Empty divs are here only because of grid and grid is used because with flex the height is not specified and auto scrolling form doesn't work correctly
  return (
    <S.Wrapper
      id={WIZARD_WRAPPER_ID}
      $hasProgressBar={progressBarShown}
      $backgroundColor={backgroundColor}
      $noPadding={noPadding}
      $hasHeader={hasHeader}
    >
      {headerContent ? (
        <S.HeaderWrap>{headerContent}</S.HeaderWrap>
      ) : (
        <div></div>
      )}
      <S.ContentWrapper
        id={WIZARD_CONTENT_WRAPPER_ID}
        $hasExpandableArea={!!expandablePanel}
        $backgroundColor={backgroundColor}
      >
        {sidebarContent && !isDesktop ? (
          renderMobileContentWithSidebar()
        ) : (
          <S.ContentWrapperInner>
            {sidebarContent ? (
              <S.Sidebar>{sidebarContent}</S.Sidebar>
            ) : (
              <div></div>
            )}
            <ErrorBoundary FallbackComponent={ErrorSkeleton}>
              <S.Content $outlined={outlined}>{body}</S.Content>
            </ErrorBoundary>
          </S.ContentWrapperInner>
        )}
        {afterBody && <S.AfterBodyWrapper>{afterBody}</S.AfterBodyWrapper>}
      </S.ContentWrapper>
      {expandablePanel && (
        <S.ExpandablePanelWrapper $maxWidth={maxWidth}>
          {expandablePanel}
        </S.ExpandablePanelWrapper>
      )}
    </S.Wrapper>
  );
};
