// TODO: BE to provide type for all error_codes?
export type ErrorCode = 'validation_error' | 'record_invalid' | string;
export type ErrorData = {
  error_message?: string;
  error_code?: ErrorCode;
};

export type ApiError = {
  status?: number;
  originalStatus?: number;
  data?: ErrorData;
};

export const DEFAULT_ERROR_MESSAGE =
  'We were not able to process your request. ';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processApiValidationErrorData = (errorData?: any): string => {
  let message: string; // TODO: verify with BE, why they return different object structure
  message = errorData?.error_fields
    ?.map(err => {
      const getErrorMessage = (err): string => {
        if (Array.isArray(err.field_error_messages)) {
          return `${err?.field_name}: ${(err?.field_error_messages || []).join(
            ', '
          )}`;
        } else if (typeof err.field_error_messages === 'string') {
          return `${err?.field_name}: ${err?.field_error_messages || ''}`;
        } else {
          const errAsObj = err?.field_error_messages as unknown as {
            [Key: number]: string;
          };

          if (errAsObj) {
            return `${err?.field_name}: ${Object.keys(errAsObj)
              .map(key => {
                const errAsObjItm = errAsObj[key] as unknown as {
                  [Key: string]: string;
                };
                return Object.keys(errAsObjItm)
                  .map(itmKey => `${itmKey}: ${errAsObjItm[itmKey]}`)
                  .join(', ');
              })
              .join(', ')}`;
          }
        }
        return '';
      };

      return getErrorMessage(err);
    })
    .join('\n');
  if (!message && errorData?.error_message) {
    message = errorData?.error_message;
  }
  return message;
};

const processErrorData = (errorData?: ErrorData) =>
  errorData?.error_message || DEFAULT_ERROR_MESSAGE;

export const processApiError = (
  error?: ApiError
): {
  message: string;
  code?: ErrorCode;
  originalError: ApiError | undefined;
} => {
  let message;
  let code = (error?.data as ErrorData)?.error_code;

  if (
    error?.data != null &&
    typeof error.data === 'object' &&
    'error_code' in error.data &&
    error.data.error_code === 'validation_error'
  ) {
    message = processApiValidationErrorData(error?.data);
  } else if (error?.originalStatus === 413) {
    // TODO: Might not need this after NPM-7977 is ready
    message = 'File cannot be uploaded because it exceeds the size limit.';
    code = 'validation_error';
  } else {
    message = processErrorData(error?.data);
  }

  return {
    message,
    code,
    originalError: error,
  };
};
