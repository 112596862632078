import React, { type ComponentProps, useEffect, useMemo } from 'react';
import { type FormInstance, useWatch } from 'antd/lib/form/Form';
import { find } from 'lodash';

import { Select } from '@npm/core/ui/components/atoms/Select';
import { PAGE_SIZE_HARD_LIMIT } from '@npm/core/ui/components/molecules/Table';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  type CodebookShow,
  type IssuerEntitiesHoldingCreateRequestContractAsset,
  CbAssetType,
} from '@npm/data-access';
import { useCodebook, useIssuerEntityShow, VenusApi } from '@npm/data-access';

import { useUserContextStore } from '../../../auth/user/context';
import { useIsInvestor } from '../../../auth/user/role/hooks/useIsInvestor';

import {
  type AssetTypeSelectOption,
  getClassFromStockClassName,
  getCommonAndPreferredAssetOptions,
  getIsOptionVenusStockClass,
  getRestAssetOptions,
  sortAssetTypeOptions,
} from './AssetTypesSelect.utils';

import * as S from './AssetTypesSelect.styles';

type Props = {
  name: string;
  issuerEntityId?: number;
  setForcedStockClass: React.Dispatch<React.SetStateAction<string>>;
  form: FormInstance<IssuerEntitiesHoldingCreateRequestContractAsset>;
  filterOptions?: (assetType: CodebookShow) => boolean;
  includeClassOptions?: boolean;
} & ComponentProps<typeof Select>;

export const AssetTypesSelect = ({
  name,
  issuerEntityId,
  onChange,
  setForcedStockClass,
  form,
  filterOptions,
  includeClassOptions = true,
  ...props
}: Props) => {
  const assetTypeValue = useWatch(name, form);

  const { data, isLoading } = useCodebook(
    {
      type: CbAssetType.code,
    },
    { onError: Select.onError }
  );

  const { data: issuerEntity } = useIssuerEntityShow(
    {
      id: issuerEntityId?.toString(),
    },
    { queryConfig: { enabled: !!issuerEntityId } }
  );

  const { data: venusStockClasses } = VenusApi.useCompanyStockClasses(
    {
      companyId: issuerEntity?.venus_id?.toString(),
      size: PAGE_SIZE_HARD_LIMIT,
    },
    {
      queryConfig: { enabled: issuerEntity?.venus_id != null },
      onError: () => {
        // The data is not critical for the component to work, so we can ignore the error
      },
    }
  );

  const isInvestor = useIsInvestor();
  const isIndividualNpmsInvestor =
    useUserContextStore(store => store.isIndividualNpmsInvestor) && isInvestor;

  const options: AssetTypeSelectOption[] = useMemo(() => {
    if (isLoading || !data) {
      return undefined;
    }

    const filteredData = filterOptions
      ? data?.codebooks?.filter(filterOptions)
      : data?.codebooks;

    return sortAssetTypeOptions(
      [
        ...getCommonAndPreferredAssetOptions(
          filteredData,
          venusStockClasses,
          includeClassOptions
        ),
        ...getRestAssetOptions(filteredData, {
          isIndividual: isIndividualNpmsInvestor,
        }),
      ],
      isIndividualNpmsInvestor
    );
  }, [
    data,
    isLoading,
    venusStockClasses,
    includeClassOptions,
    isIndividualNpmsInvestor,
  ]);

  const handleChange = (val, e) => {
    const forcedStockClass = getIsOptionVenusStockClass(val)
      ? getClassFromStockClassName(val)
      : null;

    onChange?.(val, e);
    setForcedStockClass(forcedStockClass);

    if (forcedStockClass) {
      form?.setFieldsValue({
        series: forcedStockClass,
      });
    }
  };

  // reset field if the selected asset type is not an option
  useEffect(() => {
    if (
      assetTypeValue &&
      options &&
      !find(options, { value: assetTypeValue })
    ) {
      form?.setFieldsValue({ [name]: undefined });
    }
  }, [options]);

  return (
    <S.StyledSelect
      data-cy={CypressDataIds.Filters.AssetTypeSelect}
      loading={isLoading}
      placeholder={options ? `Select (${options.length})` : 'Select'}
      options={options}
      onChange={handleChange}
      {...props}
    >
      {options?.map(option => (
        <Select.Option
          key={option.value}
          value={option.value}
          label={option.label}
        >
          {option.label}
        </Select.Option>
      ))}
    </S.StyledSelect>
  );
};
