import styled from 'styled-components';

export const AlertContainer = styled.div<{
  $margin?: string;
  $applyMargin?: boolean;
}>`
  > * {
    margin-bottom: ${({ theme, $margin }) =>
      !$margin ? theme.spacing.md : 0}px;
  }

  margin: ${({ $margin, $applyMargin }) => ($applyMargin ? $margin : 0)};
`;
