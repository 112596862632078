import type { ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import type { Holding } from '@npm/data-access';

type Props = {
  holding: Holding | undefined;
} & ComponentProps<typeof Modal>;

export const CorrectionInstructionsModal = ({
  holding,
  ...modalProps
}: Props) => (
  <Modal
    {...modalProps}
    title={`Correction Instructions for  ${holding?.id}`}
    okText="Close"
    cancelButtonProps={{ hidden: true }}
    size="md"
  >
    <Text size="sm">{holding?.correction_instructions}</Text>
  </Modal>
);
