/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AccountApi,
  AccountIndexErrorCodes,
  AccountIndexErrorTypes,
  AccountCreateErrorCodes,
  SecondMarketAccountCopyCreateErrorCodes,
  AccountUpdateErrorCodes,
  AccountShowErrorCodes,
  AccountShowErrorTypes,
  BrokerageFirmAccountIndexErrorCodes,
  BrokerageFirmAccountIndexErrorTypes,
  BrokerageFirmAccountCreateErrorCodes,
  BrokerageFirmAccountStatisticShowErrorCodes,
  BrokerageFirmAccountStatisticShowErrorTypes,
  HoldingStatisticShowErrorCodes,
  HoldingStatisticShowErrorTypes,
  IssuerEntityCompanyStatisticsErrorCodes,
  IssuerEntityCompanyStatisticsErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AccountApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountIndexMethod = AccountApi['accountIndex'];
export type AccountIndexResponseType = MethodResult<AccountIndexMethod>;

export const useAccountIndex = (
  variables: Parameters<AccountIndexMethod>[0],
  config?: UseQueryConfig<
    AccountIndexMethod,
    AccountIndexErrorTypes
  >
) => {
  return useQuery<
    AccountIndexMethod,
    AccountIndexErrorTypes
  >(
    (...args) => api.accountIndex(...args),
    AccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountIndex'],
        ...apiQueryConfig.AccountIndex,
        ...config?.queryConfig
      },
    },
    'AccountIndex',
  );
};

export const useAccountIndexInfinite = (
  variables: Parameters<AccountIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountIndexMethod,
    AccountIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountIndexMethod,
    AccountIndexErrorTypes
  >(
    (...args) => api.accountIndex(...args),
    AccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountIndex', 'AccountIndexInfinite'],
        ...apiQueryConfig.AccountIndex,
        ...config?.queryConfig,
      },
    },
    'AccountIndex',
  );
};

export const useAccountIndexLazy = (baseOptions?: {
  variables?: Parameters<AccountIndexMethod>[0],
  config?: UseQueryConfig<
    AccountIndexMethod,
    AccountIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountIndexMethod,
    AccountIndexErrorTypes
  >(
    (...args) => api.accountIndex(...args),
    AccountIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountIndex'],
        ...apiQueryConfig.AccountIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountIndex',
  );
};


export type AccountCreateMethod = AccountApi['accountCreate'];

export const useAccountCreate = () => {
  return useMutation<AccountCreateMethod>(
    (...args) => api.accountCreate(...args),
    AccountCreateErrorCodes,
    'AccountCreate',
    '/api/accounts',
    'post'
  );
};

export type SecondMarketAccountCopyCreateMethod = AccountApi['secondMarketAccountCopyCreate'];

export const useSecondMarketAccountCopyCreate = () => {
  return useMutation<SecondMarketAccountCopyCreateMethod>(
    (...args) => api.secondMarketAccountCopyCreate(...args),
    SecondMarketAccountCopyCreateErrorCodes,
    'SecondMarketAccountCopyCreate',
    '/api/accounts/{account_id}/second-market-account-copy',
    'post'
  );
};

export type AccountUpdateMethod = AccountApi['accountUpdate'];

export const useAccountUpdate = () => {
  return useMutation<AccountUpdateMethod>(
    (...args) => api.accountUpdate(...args),
    AccountUpdateErrorCodes,
    'AccountUpdate',
    '/api/accounts/{id}',
    'put'
  );
};

export type AccountShowMethod = AccountApi['accountShow'];
export type AccountShowResponseType = MethodResult<AccountShowMethod>;

export const useAccountShow = (
  variables: Parameters<AccountShowMethod>[0],
  config?: UseQueryConfig<
    AccountShowMethod,
    AccountShowErrorTypes
  >
) => {
  return useQuery<
    AccountShowMethod,
    AccountShowErrorTypes
  >(
    (...args) => api.accountShow(...args),
    AccountShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountShow'],
        ...apiQueryConfig.AccountShow,
        ...config?.queryConfig
      },
    },
    'AccountShow',
  );
};

export const useAccountShowInfinite = (
  variables: Parameters<AccountShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountShowMethod,
    AccountShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountShowMethod,
    AccountShowErrorTypes
  >(
    (...args) => api.accountShow(...args),
    AccountShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountShow', 'AccountShowInfinite'],
        ...apiQueryConfig.AccountShow,
        ...config?.queryConfig,
      },
    },
    'AccountShow',
  );
};

export const useAccountShowLazy = (baseOptions?: {
  variables?: Parameters<AccountShowMethod>[0],
  config?: UseQueryConfig<
    AccountShowMethod,
    AccountShowErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountShowMethod,
    AccountShowErrorTypes
  >(
    (...args) => api.accountShow(...args),
    AccountShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountShow'],
        ...apiQueryConfig.AccountShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountShow',
  );
};


export type BrokerageFirmAccountIndexMethod = AccountApi['brokerageFirmAccountIndex'];
export type BrokerageFirmAccountIndexResponseType = MethodResult<BrokerageFirmAccountIndexMethod>;

export const useBrokerageFirmAccountIndex = (
  variables: Parameters<BrokerageFirmAccountIndexMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmAccountIndexMethod,
    BrokerageFirmAccountIndexErrorTypes
  >
) => {
  return useQuery<
    BrokerageFirmAccountIndexMethod,
    BrokerageFirmAccountIndexErrorTypes
  >(
    (...args) => api.brokerageFirmAccountIndex(...args),
    BrokerageFirmAccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmAccountIndex'],
        ...apiQueryConfig.BrokerageFirmAccountIndex,
        ...config?.queryConfig
      },
    },
    'BrokerageFirmAccountIndex',
  );
};

export const useBrokerageFirmAccountIndexInfinite = (
  variables: Parameters<BrokerageFirmAccountIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    BrokerageFirmAccountIndexMethod,
    BrokerageFirmAccountIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    BrokerageFirmAccountIndexMethod,
    BrokerageFirmAccountIndexErrorTypes
  >(
    (...args) => api.brokerageFirmAccountIndex(...args),
    BrokerageFirmAccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmAccountIndex', 'BrokerageFirmAccountIndexInfinite'],
        ...apiQueryConfig.BrokerageFirmAccountIndex,
        ...config?.queryConfig,
      },
    },
    'BrokerageFirmAccountIndex',
  );
};

export const useBrokerageFirmAccountIndexLazy = (baseOptions?: {
  variables?: Parameters<BrokerageFirmAccountIndexMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmAccountIndexMethod,
    BrokerageFirmAccountIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    BrokerageFirmAccountIndexMethod,
    BrokerageFirmAccountIndexErrorTypes
  >(
    (...args) => api.brokerageFirmAccountIndex(...args),
    BrokerageFirmAccountIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BrokerageFirmAccountIndex'],
        ...apiQueryConfig.BrokerageFirmAccountIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BrokerageFirmAccountIndex',
  );
};


export type BrokerageFirmAccountCreateMethod = AccountApi['brokerageFirmAccountCreate'];

export const useBrokerageFirmAccountCreate = () => {
  return useMutation<BrokerageFirmAccountCreateMethod>(
    (...args) => api.brokerageFirmAccountCreate(...args),
    BrokerageFirmAccountCreateErrorCodes,
    'BrokerageFirmAccountCreate',
    '/api/brokerage-firms/{brokerage_firm_id}/accounts',
    'post'
  );
};

export type BrokerageFirmAccountStatisticShowMethod = AccountApi['brokerageFirmAccountStatisticShow'];
export type BrokerageFirmAccountStatisticShowResponseType = MethodResult<BrokerageFirmAccountStatisticShowMethod>;

export const useBrokerageFirmAccountStatisticShow = (
  variables: Parameters<BrokerageFirmAccountStatisticShowMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmAccountStatisticShowMethod,
    BrokerageFirmAccountStatisticShowErrorTypes
  >
) => {
  return useQuery<
    BrokerageFirmAccountStatisticShowMethod,
    BrokerageFirmAccountStatisticShowErrorTypes
  >(
    (...args) => api.brokerageFirmAccountStatisticShow(...args),
    BrokerageFirmAccountStatisticShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmAccountStatisticShow'],
        ...apiQueryConfig.BrokerageFirmAccountStatisticShow,
        ...config?.queryConfig
      },
    },
    'BrokerageFirmAccountStatisticShow',
  );
};

export const useBrokerageFirmAccountStatisticShowInfinite = (
  variables: Parameters<BrokerageFirmAccountStatisticShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    BrokerageFirmAccountStatisticShowMethod,
    BrokerageFirmAccountStatisticShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    BrokerageFirmAccountStatisticShowMethod,
    BrokerageFirmAccountStatisticShowErrorTypes
  >(
    (...args) => api.brokerageFirmAccountStatisticShow(...args),
    BrokerageFirmAccountStatisticShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmAccountStatisticShow', 'BrokerageFirmAccountStatisticShowInfinite'],
        ...apiQueryConfig.BrokerageFirmAccountStatisticShow,
        ...config?.queryConfig,
      },
    },
    'BrokerageFirmAccountStatisticShow',
  );
};

export const useBrokerageFirmAccountStatisticShowLazy = (baseOptions?: {
  variables?: Parameters<BrokerageFirmAccountStatisticShowMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmAccountStatisticShowMethod,
    BrokerageFirmAccountStatisticShowErrorTypes
  >
}) => {
  return useLazyQuery<
    BrokerageFirmAccountStatisticShowMethod,
    BrokerageFirmAccountStatisticShowErrorTypes
  >(
    (...args) => api.brokerageFirmAccountStatisticShow(...args),
    BrokerageFirmAccountStatisticShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BrokerageFirmAccountStatisticShow'],
        ...apiQueryConfig.BrokerageFirmAccountStatisticShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BrokerageFirmAccountStatisticShow',
  );
};


export type HoldingStatisticShowMethod = AccountApi['holdingStatisticShow'];
export type HoldingStatisticShowResponseType = MethodResult<HoldingStatisticShowMethod>;

export const useHoldingStatisticShow = (
  variables: Parameters<HoldingStatisticShowMethod>[0],
  config?: UseQueryConfig<
    HoldingStatisticShowMethod,
    HoldingStatisticShowErrorTypes
  >
) => {
  return useQuery<
    HoldingStatisticShowMethod,
    HoldingStatisticShowErrorTypes
  >(
    (...args) => api.holdingStatisticShow(...args),
    HoldingStatisticShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['HoldingStatisticShow'],
        ...apiQueryConfig.HoldingStatisticShow,
        ...config?.queryConfig
      },
    },
    'HoldingStatisticShow',
  );
};

export const useHoldingStatisticShowInfinite = (
  variables: Parameters<HoldingStatisticShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    HoldingStatisticShowMethod,
    HoldingStatisticShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    HoldingStatisticShowMethod,
    HoldingStatisticShowErrorTypes
  >(
    (...args) => api.holdingStatisticShow(...args),
    HoldingStatisticShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['HoldingStatisticShow', 'HoldingStatisticShowInfinite'],
        ...apiQueryConfig.HoldingStatisticShow,
        ...config?.queryConfig,
      },
    },
    'HoldingStatisticShow',
  );
};

export const useHoldingStatisticShowLazy = (baseOptions?: {
  variables?: Parameters<HoldingStatisticShowMethod>[0],
  config?: UseQueryConfig<
    HoldingStatisticShowMethod,
    HoldingStatisticShowErrorTypes
  >
}) => {
  return useLazyQuery<
    HoldingStatisticShowMethod,
    HoldingStatisticShowErrorTypes
  >(
    (...args) => api.holdingStatisticShow(...args),
    HoldingStatisticShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['HoldingStatisticShow'],
        ...apiQueryConfig.HoldingStatisticShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'HoldingStatisticShow',
  );
};


export type IssuerEntityCompanyStatisticsMethod = AccountApi['issuerEntityCompanyStatistics'];
export type IssuerEntityCompanyStatisticsResponseType = MethodResult<IssuerEntityCompanyStatisticsMethod>;

export const useIssuerEntityCompanyStatistics = (
  variables: Parameters<IssuerEntityCompanyStatisticsMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityCompanyStatisticsMethod,
    IssuerEntityCompanyStatisticsErrorTypes
  >
) => {
  return useQuery<
    IssuerEntityCompanyStatisticsMethod,
    IssuerEntityCompanyStatisticsErrorTypes
  >(
    (...args) => api.issuerEntityCompanyStatistics(...args),
    IssuerEntityCompanyStatisticsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityCompanyStatistics'],
        ...apiQueryConfig.IssuerEntityCompanyStatistics,
        ...config?.queryConfig
      },
    },
    'IssuerEntityCompanyStatistics',
  );
};

export const useIssuerEntityCompanyStatisticsInfinite = (
  variables: Parameters<IssuerEntityCompanyStatisticsMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerEntityCompanyStatisticsMethod,
    IssuerEntityCompanyStatisticsErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerEntityCompanyStatisticsMethod,
    IssuerEntityCompanyStatisticsErrorTypes
  >(
    (...args) => api.issuerEntityCompanyStatistics(...args),
    IssuerEntityCompanyStatisticsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityCompanyStatistics', 'IssuerEntityCompanyStatisticsInfinite'],
        ...apiQueryConfig.IssuerEntityCompanyStatistics,
        ...config?.queryConfig,
      },
    },
    'IssuerEntityCompanyStatistics',
  );
};

export const useIssuerEntityCompanyStatisticsLazy = (baseOptions?: {
  variables?: Parameters<IssuerEntityCompanyStatisticsMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityCompanyStatisticsMethod,
    IssuerEntityCompanyStatisticsErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerEntityCompanyStatisticsMethod,
    IssuerEntityCompanyStatisticsErrorTypes
  >(
    (...args) => api.issuerEntityCompanyStatistics(...args),
    IssuerEntityCompanyStatisticsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerEntityCompanyStatistics'],
        ...apiQueryConfig.IssuerEntityCompanyStatistics,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerEntityCompanyStatistics',
  );
};

