import React, { useCallback } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CypressDataIds } from '@npm/core/ui/constants';
import { downloadBlob } from '@npm/core/ui/utils/apiHelper';
import { useDocumentDownloadLazy } from '@npm/data-access';

import { type Document, type DocumentWithId } from './DocumentViewer.types';
import { WebviewerWrapper } from './WebviewerWrapper';

import * as S from './DocumentViewerModal.styles';

type Props = {
  document: Document;
  documentMetadata:
    | {
        displayName: string | null | undefined;
        downloadable: boolean | null | undefined;
      }
    | null
    | undefined;
  isOpen: boolean;
  handleCancel: () => void;
  handleDownload?: () => void;
};

const ModalFooter = ({
  handleCancel,
  document,
  documentMetadata,
  handleDownload,
}: Pick<
  Props,
  'handleCancel' | 'documentMetadata' | 'document' | 'handleDownload'
>) => {
  const [executeDownload, { isLoading }] = useDocumentDownloadLazy({
    config: {
      axiosConfig: { responseType: 'blob' },
    },
  });

  const npmDocumentId = document?.type === 'with-npm-id' ? document.id : null;
  const downloadNpmDocument = useCallback(async () => {
    try {
      const data = await executeDownload({ variables: { id: npmDocumentId } });
      downloadBlob(data, documentMetadata?.displayName);
    } catch (e) {
      console.error(e);
    }
  }, [npmDocumentId, documentMetadata]);

  return (
    <S.FooterWrapper>
      <Button variant={'default'} onClick={handleCancel} blockOnMobile>
        Close
      </Button>
      {(documentMetadata?.downloadable && !!npmDocumentId) || handleDownload ? (
        <Button
          variant={'outline'}
          loading={isLoading}
          blockOnMobile
          onClick={handleDownload ? handleDownload : downloadNpmDocument}
          icon={<Icon name="download" />}
        >
          Download
        </Button>
      ) : null}
    </S.FooterWrapper>
  );
};

export const DocumentViewerModal = ({
  isOpen,
  handleCancel,
  document,
  documentMetadata,
  handleDownload,
}: Props) => {
  return (
    <S.Modal
      data-cy={CypressDataIds.DocumentPreview.Modal}
      destroyOnClose={true}
      open={isOpen}
      title={documentMetadata?.displayName ?? 'Document preview'}
      onCancel={handleCancel}
      footer={
        <ModalFooter
          handleCancel={handleCancel}
          documentMetadata={documentMetadata}
          document={document}
          handleDownload={handleDownload}
        />
      }
    >
      <WebviewerWrapper
        key={(document as DocumentWithId)?.id}
        document={document}
        mode="full"
        handleClose={handleCancel}
      />
    </S.Modal>
  );
};
