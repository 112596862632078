/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { WealthConnectStatus } from '../model';
/**
 * WealthConnectStatusApi - axios parameter creator
 * @export
 */
export const WealthConnectStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list referrals
         * @param {number} eventId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wealthConnectStatus: async (eventId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('wealthConnectStatus', 'eventId', eventId)
            const localVarPath = changeUrl(`/api/events/{event_id}/wealth-connect-status`)
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WealthConnectStatusApi - functional programming interface
 * @export
 */
export const WealthConnectStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WealthConnectStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list referrals
         * @param {number} eventId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wealthConnectStatus(eventId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WealthConnectStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wealthConnectStatus(eventId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WealthConnectStatusApi - factory interface
 * @export
 */
export const WealthConnectStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WealthConnectStatusApiFp(configuration)
    return {
        /**
         * 
         * @summary list referrals
         * @param {number} eventId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wealthConnectStatus(eventId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<WealthConnectStatus> {
            return localVarFp.wealthConnectStatus(eventId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for wealthConnectStatus operation in WealthConnectStatusApi.
 * @export
 * @interface WealthConnectStatusApiWealthConnectStatusRequest
 */
export interface WealthConnectStatusApiWealthConnectStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof WealthConnectStatusApiWealthConnectStatus
     */
    readonly eventId: number

    /**
     * 
     * @type {string}
     * @memberof WealthConnectStatusApiWealthConnectStatus
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof WealthConnectStatusApiWealthConnectStatus
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof WealthConnectStatusApiWealthConnectStatus
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof WealthConnectStatusApiWealthConnectStatus
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof WealthConnectStatusApiWealthConnectStatus
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof WealthConnectStatusApiWealthConnectStatus
     */
    readonly xUserRoleId?: string
}

/**
 * WealthConnectStatusApi - object-oriented interface
 * @export
 * @class WealthConnectStatusApi
 * @extends {BaseAPI}
 */
export class WealthConnectStatusApi extends BaseAPI {
    /**
     * 
     * @summary list referrals
     * @param {WealthConnectStatusApiWealthConnectStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WealthConnectStatusApi
     */
    public wealthConnectStatus(requestParameters: WealthConnectStatusApiWealthConnectStatusRequest, options?: AxiosRequestConfig) {
        return WealthConnectStatusApiFp(this.configuration).wealthConnectStatus(requestParameters.eventId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type WealthConnectStatusErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const WealthConnectStatusErrorCodes = [
    401,
];


