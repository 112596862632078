import React, { useState } from 'react';
import { type AlertProps } from 'antd';

import { Flex } from '../common';
import { Icon } from '../Icon';

import * as S from './Alert.styles';

type Props = AlertProps & {
  expandable?: boolean;
  centerIcon?: boolean;
  centered?: boolean;
};

export const Alert = ({
  type = 'info',
  icon,
  showIcon = true,
  centerIcon = false,
  centered = false,
  expandable = true,
  description,
  message,
  ...props
}: Props) => {
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  const defaultAlertIcon = () => {
    switch (type) {
      case 'success':
        return 'circle-check';
      case 'info':
        return 'info-circle';
      case 'warning':
        return 'warning';
      case 'error':
        return 'alert-triangle';
      default:
        return 'info-circle';
    }
  };

  return (
    <S.StyledAlert
      {...props}
      message={
        <Flex justify="space-between">
          <div
            className={'ant-custom-alert-message-text'}
            style={{ whiteSpace: 'normal' }}
          >
            {message}
          </div>
          {description && expandable ? (
            <S.ReadMoreButton
              size="sm"
              onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
              $variant={type}
            >
              {isDescriptionVisible ? 'Read less' : 'Read more'}
            </S.ReadMoreButton>
          ) : null}
        </Flex>
      }
      closeIcon={<Icon name="x-close" size="xxs" />}
      type={type}
      showIcon={showIcon}
      hasDescription={Boolean(description)}
      icon={
        <S.IconWrap $centerIcon={centerIcon} className="icon-wrap">
          {icon || <Icon name={defaultAlertIcon()} size="xs" />}
        </S.IconWrap>
      }
      description={
        description && (isDescriptionVisible || !expandable)
          ? description
          : null
      }
      $centered={centered}
    />
  );
};
