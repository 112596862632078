import React, { type FC, useState } from 'react';
import { Upload } from 'antd';
import { type RcFile } from 'antd/lib/upload/interface';
import { useTheme } from 'styled-components';

import { CypressDataIds } from '../../../constants';
import { Button } from '../Button';
import { type ButtonVariant } from '../Button/Button.types';
import { Icon } from '../Icon';

import {
  DEFAULT_SUPPORTED_CONTENT_TYPES,
  DEFAULT_SUPPORTED_FILE_EXTENSIONS,
} from './FileUpload.constants';

import * as S from './FileUpload.styles';

type Props = {
  onChange?: (file: File | null) => void;
  initialFile?: {
    uid: string;
    name: string;
  };
  label?: string;
  fileSelectedLabel?: string;
  supportedTypes?: string[];
  acceptFileTypes?: string[];
  allowClear?: boolean;
  buttonVariant?: ButtonVariant;
  isLoading?: boolean;
  disabled?: boolean;
  allowRemoveInitialFile?: boolean;
  resetOnDelete?: boolean;
};

export const FileUpload: FC<Props> = ({
  onChange,
  initialFile,
  label = 'Upload file (Max: 1)',
  fileSelectedLabel = 'Replace file',
  supportedTypes = DEFAULT_SUPPORTED_CONTENT_TYPES,
  acceptFileTypes = DEFAULT_SUPPORTED_FILE_EXTENSIONS,
  allowClear = true,
  buttonVariant,
  isLoading = false,
  disabled,
  allowRemoveInitialFile,
  resetOnDelete,
  ...rest
}) => {
  const theme = useTheme();
  const [error, setError] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<RcFile | null>(
    initialFile as RcFile
  );

  const onFileValidated = (file: RcFile) => {
    setSelectedFile(file);
    onChange?.(file);
  };

  const onError = (error: string) => {
    setError(error);
    setSelectedFile(null);
    onChange?.(null);
  };

  const clearSelected = () => {
    setSelectedFile(resetOnDelete ? null : (initialFile as RcFile));
    setError(null);
    onChange?.(null);
  };

  const isInitial = selectedFile?.uid === initialFile?.uid;

  return (
    <S.Container>
      <Upload
        disabled={disabled}
        data-cy={rest['data-cy']}
        accept={acceptFileTypes.join(',')}
        beforeUpload={file => {
          setError(null);
          if (!supportedTypes?.includes(file.type)) {
            onError('File type is not supported.');
          } else {
            onFileValidated(file);
          }
          return false;
        }}
        showUploadList={false}
        fileList={selectedFile ? [selectedFile] : []}
      >
        <Button
          disabled={disabled}
          stopPropagation={false}
          data-cy={CypressDataIds.FileUpload.Drawer.UploadFileButton}
          variant={
            buttonVariant ??
            (selectedFile || initialFile ? 'outline' : 'default')
          }
          icon={<Icon name="upload" />}
          loading={isLoading}
        >
          {selectedFile?.name || initialFile ? fileSelectedLabel : label}
        </Button>
      </Upload>
      {(error || selectedFile) && (
        <S.BottomContainer>
          <S.PaperClipWrapper $error={!!error}>
            <Icon name="paperclip" size="xs" />
          </S.PaperClipWrapper>
          {error ? (
            <S.ErrorMsg>{error}</S.ErrorMsg>
          ) : (
            <S.FileName>{selectedFile?.name}</S.FileName>
          )}
          {error || (allowClear && (allowRemoveInitialFile || !isInitial)) ? (
            <S.TrashButton onClick={clearSelected}>
              <Icon
                name="trash"
                size="xs"
                color={theme.color.error.typography.primary}
              />
            </S.TrashButton>
          ) : null}
        </S.BottomContainer>
      )}
    </S.Container>
  );
};
