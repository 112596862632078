import { type ComponentProps, useCallback, useState } from 'react';

import { useAccountShow, useIssuerEntityShow } from '@npm/data-access';

import { type HoldingDrawer } from './HoldingDrawer';

type Params = {
  preselectedIssuerEntityId?: number;
  preselectedAccountId?: number;
  preOpened?: boolean;
};

export const useHoldingDrawer = ({
  preselectedIssuerEntityId,
  preselectedAccountId,
  preOpened,
}: Params = {}): [
  {
    openAddHoldingDrawer: () => void;
    openEditHoldingDrawer: (id: number) => void;
    setEditMode: (id: number) => void;
  },
  Pick<
    ComponentProps<typeof HoldingDrawer>,
    'id' | 'onClose' | 'open' | 'initialIssuerEntity' | 'initialAccount'
  >
] => {
  const [open, setOpen] = useState(!!preOpened);
  const [holdingId, setHoldingId] = useState<number>(null);

  const { data: issuerEntity } = useIssuerEntityShow(
    { id: preselectedIssuerEntityId?.toString() },
    { queryConfig: { enabled: !!preselectedIssuerEntityId } }
  );

  const { data: selectedAccount } = useAccountShow(
    { id: preselectedAccountId?.toString() },
    {
      queryConfig: {
        enabled: !!preselectedAccountId,
      },
    }
  );

  const openAddHoldingDrawer = useCallback(() => {
    setOpen(true);
    setHoldingId(null);
  }, [setOpen, setHoldingId]);

  const openEditHoldingDrawer = useCallback(
    (id: number) => {
      setHoldingId(id);
      setOpen(true);
    },
    [setOpen, setHoldingId]
  );

  const closeDrawer = useCallback(() => {
    setOpen(false);
    setHoldingId(null);
  }, [setOpen, setHoldingId]);

  // edge case - when adding the holding is successful but following upload fails,
  // we want to stay in drawer, but change it to "edit" mode
  const setEditMode = (id: number) => {
    setHoldingId(id);
  };

  return [
    { openAddHoldingDrawer, openEditHoldingDrawer, setEditMode },
    {
      open,
      id: holdingId,
      onClose: closeDrawer,
      initialIssuerEntity: issuerEntity,
      initialAccount: selectedAccount,
    },
  ];
};
