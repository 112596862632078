import React from 'react';

import { Banner } from '@npm/core/ui/components/atoms/Banner';
import { Button } from '@npm/core/ui/components/atoms/Button';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { TableVertical } from '@npm/core/ui/components/molecules/TableVertical';
import { CypressDataIds } from '@npm/core/ui/constants';
import { CbOwnerType } from '@npm/data-access';

import { CardLabel } from './CardLabel';
import { LoadingProgramCard } from './LoadingProgramCard';
import {
  getBannerDescriptionByPhase,
  getBannerMessageByPhase,
  getBannerTypeByPhase,
  getProgramCardRows,
} from './ProgramCard.config';
import { type ProgramProps, type Status } from './ProgramCard.types';

import * as S from './ProgramCard.styles';

export const ProgramCard = ({
  isLoading,
  isButtonLoading,
  onClick,
  event,
  title,
  showStatus = true,
  workstation,
  className,
  shortened,
  hideFields,
  hideLogo,
  hideEventName,
  feeConfiguration,
  hideButton = false,
  ctaText,
  buttonProps,
  ...cardProps
}: ProgramProps) => {
  const { current_phase, issuer_entity, expired } = event || {};

  const buttonLoading =
    typeof isButtonLoading === 'function'
      ? isButtonLoading(event)
      : isButtonLoading;

  if (isLoading) {
    return <LoadingProgramCard status={current_phase?.code as Status} />;
  }

  return (
    <S.Card
      header={
        hideLogo
          ? {
              title: title ?? 'Program Overview',
              label: (
                <CardLabel role={event?.event_role} hideFields={hideFields} />
              ),
            }
          : {
              iconText: issuer_entity?.name,
              icon: (
                <CompanyLogo
                  url={issuer_entity?.logo_url}
                  title={issuer_entity?.name}
                  size="sm"
                />
              ),
              label: (
                <CardLabel role={event?.event_role} hideFields={hideFields} />
              ),
            }
      }
      noContentPadding
      className={className}
      data-cy={CypressDataIds.InvestorWorkstation.LivePrograms.ProgramCard.Card}
      {...cardProps}
    >
      <S.Content>
        {!shortened && !hideEventName && (
          <Heading marginBottom="md" variant="h3">
            {event?.name}
          </Heading>
        )}
        {showStatus && !shortened && (
          <Margin bottom="md">
            <Banner
              type={getBannerTypeByPhase(current_phase?.code)}
              message={getBannerMessageByPhase(current_phase, expired)}
              description={getBannerDescriptionByPhase(event)}
            />
          </Margin>
        )}
        <TableVertical
          rows={getProgramCardRows(
            workstation,
            shortened,
            hideFields,
            onClick,
            feeConfiguration,
            event?.type?.code === CbOwnerType.items.InformationalEvent
          )}
          data={event}
        />
        {onClick && !hideButton && (
          <S.Action>
            {workstation === 'investor' && (
              <Button
                onClick={() => onClick(event)}
                loading={buttonLoading}
                data-cy={
                  CypressDataIds.InvestorWorkstation.LivePrograms.ProgramCard
                    .AccessButton
                }
                block
                {...buttonProps}
              >
                {ctaText ?? 'Access'}
              </Button>
            )}
            {workstation === 'issuer' && (
              <Button
                onClick={() => onClick(event)}
                loading={buttonLoading}
                block
                {...buttonProps}
              >
                {ctaText ?? 'Dashboard'}
              </Button>
            )}
          </S.Action>
        )}
      </S.Content>
    </S.Card>
  );
};
