/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  StepStatusApi,
  EventSubmissionStepStatusCompleteErrorCodes,
  EventSubmissionStepStatusErrorCodes,
  EventSubmissionStepStatusErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new StepStatusApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EventSubmissionStepStatusCompleteMethod = StepStatusApi['eventSubmissionStepStatusComplete'];

export const useEventSubmissionStepStatusComplete = () => {
  return useMutation<EventSubmissionStepStatusCompleteMethod>(
    (...args) => api.eventSubmissionStepStatusComplete(...args),
    EventSubmissionStepStatusCompleteErrorCodes,
    'EventSubmissionStepStatusComplete',
    '/api/events/{id}/submissions/{submission_id}/step-status',
    'post'
  );
};

export type EventSubmissionStepStatusMethod = StepStatusApi['eventSubmissionStepStatus'];
export type EventSubmissionStepStatusResponseType = MethodResult<EventSubmissionStepStatusMethod>;

export const useEventSubmissionStepStatus = (
  variables: Parameters<EventSubmissionStepStatusMethod>[0],
  config?: UseQueryConfig<
    EventSubmissionStepStatusMethod,
    EventSubmissionStepStatusErrorTypes
  >
) => {
  return useQuery<
    EventSubmissionStepStatusMethod,
    EventSubmissionStepStatusErrorTypes
  >(
    (...args) => api.eventSubmissionStepStatus(...args),
    EventSubmissionStepStatusErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventSubmissionStepStatus'],
        ...apiQueryConfig.EventSubmissionStepStatus,
        ...config?.queryConfig
      },
    },
    'EventSubmissionStepStatus',
  );
};

export const useEventSubmissionStepStatusInfinite = (
  variables: Parameters<EventSubmissionStepStatusMethod>[0],
  config?: UseInfiniteQueryConfig<
    EventSubmissionStepStatusMethod,
    EventSubmissionStepStatusErrorTypes
  >
) => {
  return useInfiniteQuery<
    EventSubmissionStepStatusMethod,
    EventSubmissionStepStatusErrorTypes
  >(
    (...args) => api.eventSubmissionStepStatus(...args),
    EventSubmissionStepStatusErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventSubmissionStepStatus', 'EventSubmissionStepStatusInfinite'],
        ...apiQueryConfig.EventSubmissionStepStatus,
        ...config?.queryConfig,
      },
    },
    'EventSubmissionStepStatus',
  );
};

export const useEventSubmissionStepStatusLazy = (baseOptions?: {
  variables?: Parameters<EventSubmissionStepStatusMethod>[0],
  config?: UseQueryConfig<
    EventSubmissionStepStatusMethod,
    EventSubmissionStepStatusErrorTypes
  >
}) => {
  return useLazyQuery<
    EventSubmissionStepStatusMethod,
    EventSubmissionStepStatusErrorTypes
  >(
    (...args) => api.eventSubmissionStepStatus(...args),
    EventSubmissionStepStatusErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EventSubmissionStepStatus'],
        ...apiQueryConfig.EventSubmissionStepStatus,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EventSubmissionStepStatus',
  );
};

