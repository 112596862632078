/**
 * @TODO: Move this back to UI,
 * we need to refactor codebooks to have no dependency on JSX or icons.
 *
 */

import { ReactComponent as Adjust } from './svg/adjust.svg';
import { ReactComponent as AdjustOff } from './svg/adjust-off.svg';
import { ReactComponent as AlertTriangle } from './svg/alert-triangle.svg';
import { ReactComponent as AllMarkets } from './svg/all-markets.svg';
import { ReactComponent as ArrowBackUp } from './svg/arrow-back-up.svg';
import { ReactComponent as ArrowNarrow45Down } from './svg/arrow-narrow-45-down.svg';
import { ReactComponent as ArrowNarrow45Up } from './svg/arrow-narrow-45-up.svg';
import { ReactComponent as ArrowNarrowLeft } from './svg/arrow-narrow-left.svg';
import { ReactComponent as ArrowNarrowRight } from './svg/arrow-narrow-right.svg';
import { ReactComponent as ArrowsExchangeHorizontal } from './svg/arrows-exchange-horizontal.svg';
import { ReactComponent as ArrowsExchangeVertical } from './svg/arrows-exchange-vertical.svg';
import { ReactComponent as ArrowsFork } from './svg/arrows-fork.svg';
import { ReactComponent as ArrowsHorizontal } from './svg/arrows-horizontal.svg';
import { ReactComponent as ArrowsMoveVertical } from './svg/arrows-move-vertical.svg';
import { ReactComponent as ArrowsOpposite } from './svg/arrows-opposite.svg';
import { ReactComponent as ArrowsSplit } from './svg/arrows-split.svg';
import { ReactComponent as Badge } from './svg/badge.svg';
import { ReactComponent as Ballpen } from './svg/ballpen.svg';
import { ReactComponent as Bell } from './svg/bell.svg';
import { ReactComponent as Building } from './svg/building.svg';
import { ReactComponent as BuildingBank } from './svg/building-bank.svg';
import { ReactComponent as Calendar } from './svg/calendar.svg';
import { ReactComponent as Chart } from './svg/chart.svg';
import { ReactComponent as ChartBar } from './svg/chart-bar.svg';
import { ReactComponent as ChartPie } from './svg/chart-pie.svg';
import { ReactComponent as Check } from './svg/check.svg';
import { ReactComponent as CheckDouble } from './svg/check-double.svg';
import { ReactComponent as ChevronDown } from './svg/chevron-down.svg';
import { ReactComponent as ChevronLeft } from './svg/chevron-left.svg';
import { ReactComponent as ChevronOpposite } from './svg/chevron-opposite.svg';
import { ReactComponent as ChevronRight } from './svg/chevron-right.svg';
import { ReactComponent as ChevronUp } from './svg/chevron-up.svg';
import { ReactComponent as Circle } from './svg/circle.svg';
import { ReactComponent as CircleCheck } from './svg/circle-check.svg';
import { ReactComponent as CircleCheckFilled } from './svg/circle-check-filled.svg';
import { ReactComponent as CircleMinus } from './svg/circle-minus.svg';
import { ReactComponent as CirclePlus } from './svg/circle-plus.svg';
import { ReactComponent as CircleX } from './svg/circle-x.svg';
import { ReactComponent as CircleXFilled } from './svg/circle-x-filled.svg';
import { ReactComponent as ClipboardCheck } from './svg/clipboard-check.svg';
import { ReactComponent as ClipboardText } from './svg/clipboard-text.svg';
import { ReactComponent as Clock } from './svg/clock.svg';
import { ReactComponent as CloseTable } from './svg/close-table.svg';
import { ReactComponent as Coin } from './svg/coin.svg';
import { ReactComponent as Collapse } from './svg/collapse.svg';
import { ReactComponent as CornerDownRight } from './svg/corner-down-right.svg';
import { ReactComponent as CurrencyDollar } from './svg/currency-dollar.svg';
import { ReactComponent as Diamond } from './svg/diamond.svg';
import { ReactComponent as Dot } from './svg/dot.svg';
import { ReactComponent as DotsVertical } from './svg/dots-vertical.svg';
import { ReactComponent as Download } from './svg/download.svg';
import { ReactComponent as DropletFilled } from './svg/droplet-filled.svg';
import { ReactComponent as Edit } from './svg/edit.svg';
import { ReactComponent as Equals } from './svg/equals.svg';
import { ReactComponent as Error404 } from './svg/error-404.svg';
import { ReactComponent as Expand } from './svg/expand.svg';
import { ReactComponent as ExternalLink } from './svg/external-link.svg';
import { ReactComponent as Eye } from './svg/eye.svg';
import { ReactComponent as EyeOff } from './svg/eye-off.svg';
import { ReactComponent as File } from './svg/file.svg';
import { ReactComponent as FilePdf } from './svg/file-pdf.svg';
import { ReactComponent as FileText } from './svg/file-text.svg';
import { ReactComponent as Filter } from './svg/filter.svg';
import { ReactComponent as FilterOff } from './svg/filter-off.svg';
import { ReactComponent as Folder } from './svg/folder.svg';
import { ReactComponent as FolderOpen } from './svg/folder-open.svg';
import { ReactComponent as FullScreen } from './svg/full-screen.svg';
import { ReactComponent as HandCheck } from './svg/hand-check.svg';
import { ReactComponent as HandClick } from './svg/hand-click.svg';
import { ReactComponent as HandDollarDown } from './svg/hand-dollar-down.svg';
import { ReactComponent as HandDollarUp } from './svg/hand-dollar-up.svg';
import { ReactComponent as HandFinger } from './svg/hand-finger.svg';
import { ReactComponent as HandStop } from './svg/hand-stop.svg';
import { ReactComponent as History } from './svg/history.svg';
import { ReactComponent as Home } from './svg/home.svg';
import { ReactComponent as InfoCircle } from './svg/info-circle.svg';
import { ReactComponent as LayoutDashboard } from './svg/layout-dashboard.svg';
import { ReactComponent as LayoutSidebar } from './svg/layout-sidebar.svg';
import { ReactComponent as LayoutSidebarCollapse } from './svg/layout-sidebar-collapse.svg';
import { ReactComponent as LayoutSidebarUncollapse } from './svg/layout-sidebar-uncollapse.svg';
import { ReactComponent as Lifebuoy } from './svg/lifebuoy.svg';
import { ReactComponent as Lightning } from './svg/lightning.svg';
import { ReactComponent as List } from './svg/list.svg';
import { ReactComponent as Loader } from './svg/loader.svg';
import { ReactComponent as Lock } from './svg/lock.svg';
import { ReactComponent as Logout } from './svg/logout.svg';
import { ReactComponent as Mail } from './svg/mail.svg';
import { ReactComponent as MailSent } from './svg/mail-sent.svg';
import { ReactComponent as MapPin } from './svg/map-pin.svg';
import { ReactComponent as Menu } from './svg/menu.svg';
import { ReactComponent as Menu2 } from './svg/menu-2.svg';
import { ReactComponent as Message } from './svg/message.svg';
import { ReactComponent as MessageReport } from './svg/message-report.svg';
import { ReactComponent as MoonFilled } from './svg/moon-filled.svg';
import { ReactComponent as MoonUnfilled } from './svg/moon-unfilled.svg';
import { ReactComponent as OpenTable } from './svg/open-table.svg';
import { ReactComponent as OrderChild } from './svg/order-child.svg';
import { ReactComponent as Paperclip } from './svg/paperclip.svg';
import { ReactComponent as PersonShield } from './svg/person-shield.svg';
import { ReactComponent as Photo } from './svg/photo.svg';
import { ReactComponent as PhotoOff } from './svg/photo-off.svg';
import { ReactComponent as PhotoX } from './svg/photo-x.svg';
import { ReactComponent as Plus } from './svg/plus.svg';
import { ReactComponent as PlusMinus } from './svg/plus-minus.svg';
import { ReactComponent as Print } from './svg/print.svg';
import { ReactComponent as Receipt } from './svg/receipt.svg';
import { ReactComponent as Redirect } from './svg/redirect.svg';
import { ReactComponent as Refresh } from './svg/refresh.svg';
import { ReactComponent as RotatedScreen } from './svg/rotated-screen.svg';
import { ReactComponent as Scale } from './svg/scale.svg';
import { ReactComponent as Search } from './svg/search.svg';
import { ReactComponent as Segment } from './svg/segment.svg';
import { ReactComponent as Settings } from './svg/settings.svg';
import { ReactComponent as Shield } from './svg/shield.svg';
import { ReactComponent as SmallScreen } from './svg/small-screen.svg';
import { ReactComponent as SortAscending } from './svg/sort-ascending.svg';
import { ReactComponent as SortAscendingSecond } from './svg/sort-ascending-2.svg';
import { ReactComponent as SortDescending } from './svg/sort-descending.svg';
import { ReactComponent as SortDescendingSecond } from './svg/sort-descending-2.svg';
import { ReactComponent as SortDown } from './svg/sort-down.svg';
import { ReactComponent as SortUp } from './svg/sort-up.svg';
import { ReactComponent as Star } from './svg/star.svg';
import { ReactComponent as StarFilled } from './svg/star-filled.svg';
import { ReactComponent as SunFilled } from './svg/sun-filled.svg';
import { ReactComponent as Table } from './svg/table.svg';
import { ReactComponent as TableUpload } from './svg/table-upload.svg';
import { ReactComponent as Tag } from './svg/tag.svg';
import { ReactComponent as Trash } from './svg/trash.svg';
import { ReactComponent as Upload } from './svg/upload.svg';
import { ReactComponent as User } from './svg/user.svg';
import { ReactComponent as UserCircle } from './svg/user-circle.svg';
import { ReactComponent as Users } from './svg/users.svg';
import { ReactComponent as Warning } from './svg/warning.svg';
import { ReactComponent as World } from './svg/world.svg';
import { ReactComponent as XClose } from './svg/x-close.svg';
import { ReactComponent as ZoomIn } from './svg/zoom-in.svg';
import { ReactComponent as ZoomOut } from './svg/zoom-out.svg';

export const icons = {
  adjust: Adjust,
  'adjust-off': AdjustOff,
  'alert-triangle': AlertTriangle,
  'all-markets': AllMarkets,
  'arrow-back-up': ArrowBackUp,
  'arrow-narrow-left': ArrowNarrowLeft,
  'arrow-narrow-right': ArrowNarrowRight,
  'arrow-narrow-45-up': ArrowNarrow45Up,
  'arrow-narrow-45-down': ArrowNarrow45Down,
  'arrows-horizontal': ArrowsHorizontal,
  'arrows-fork': ArrowsFork,
  'arrows-exchange-horizontal': ArrowsExchangeHorizontal,
  'arrows-exchange-vertical': ArrowsExchangeVertical,
  'arrows-move-vertical': ArrowsMoveVertical,
  'arrows-opposite': ArrowsOpposite,
  'arrows-split': ArrowsSplit,
  badge: Badge,
  ballpen: Ballpen,
  bell: Bell,
  building: Building,
  'building-bank': BuildingBank,
  calendar: Calendar,
  chart: Chart,
  'chart-bar': ChartBar,
  'chart-pie': ChartPie,
  check: Check,
  'check-double': CheckDouble,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'chevron-opposite': ChevronOpposite,
  circle: Circle,
  'circle-check': CircleCheck,
  'circle-check-filled': CircleCheckFilled,
  'circle-minus': CircleMinus,
  'circle-plus': CirclePlus,
  'circle-x': CircleX,
  'circle-x-filled': CircleXFilled,
  'clipboard-check': ClipboardCheck,
  'clipboard-text': ClipboardText,
  clock: Clock,
  'close-table': CloseTable,
  coin: Coin,
  collapse: Collapse,
  'corner-down-right': CornerDownRight,
  'currency-dollar': CurrencyDollar,
  dot: Dot,
  'dots-vertical': DotsVertical,
  download: Download,
  'droplet-filled': DropletFilled,
  diamond: Diamond,
  edit: Edit,
  equals: Equals,
  'error-404': Error404,
  expand: Expand,
  'external-link': ExternalLink,
  eye: Eye,
  'eye-off': EyeOff,
  file: File,
  'file-pdf': FilePdf,
  'file-text': FileText,
  filter: Filter,
  'filter-off': FilterOff,
  folder: Folder,
  'folder-open': FolderOpen,
  'full-screen': FullScreen,
  'hand-check': HandCheck,
  'hand-click': HandClick,
  'hand-dollar-down': HandDollarDown,
  'hand-dollar-up': HandDollarUp,
  'hand-finger': HandFinger,
  'hand-stop': HandStop,
  history: History,
  home: Home,
  'info-circle': InfoCircle,
  'layout-dashboard': LayoutDashboard,
  'layout-sidebar': LayoutSidebar,
  'layout-sidebar-collapse': LayoutSidebarCollapse,
  'layout-sidebar-uncollapse': LayoutSidebarUncollapse,
  lifebuoy: Lifebuoy,
  lightning: Lightning,
  list: List,
  loader: Loader,
  lock: Lock,
  logout: Logout,
  mail: Mail,
  'mail-sent': MailSent,
  'map-pin': MapPin,
  menu: Menu,
  'menu-2': Menu2,
  message: Message,
  'message-report': MessageReport,
  'moon-filled': MoonFilled,
  'moon-unfilled': MoonUnfilled,
  'open-table': OpenTable,
  'order-child': OrderChild,
  paperclip: Paperclip,
  'person-shield': PersonShield,
  photo: Photo,
  'photo-off': PhotoOff,
  'photo-x': PhotoX,
  plus: Plus,
  'plus-minus': PlusMinus,
  print: Print,
  receipt: Receipt,
  redirect: Redirect,
  refresh: Refresh,
  'rotated-screen': RotatedScreen,
  scale: Scale,
  search: Search,
  segment: Segment,
  settings: Settings,
  shield: Shield,
  'sort-down': SortDown,
  'sort-up': SortUp,
  'sort-ascending-2': SortAscendingSecond,
  'sort-ascending': SortAscending,
  'sort-descending-2': SortDescendingSecond,
  'sort-descending': SortDescending,
  star: Star,
  'star-filled': StarFilled,
  'sun-filled': SunFilled,
  'small-screen': SmallScreen,
  table: Table,
  tag: Tag,
  'table-upload': TableUpload,
  trash: Trash,
  upload: Upload,
  user: User,
  'user-circle': UserCircle,
  users: Users,
  warning: Warning,
  world: World,
  'x-close': XClose,
  'zoom-in': ZoomIn,
  'zoom-out': ZoomOut,
};

export const staticColorIcons: (keyof typeof icons)[] = [
  'close-table',
  'open-table',
];

export type IconNames = keyof typeof icons | 'pulsing-dot';
