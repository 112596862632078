import React from 'react';
import { Tooltip } from 'antd';

import { InfoIconWrap, Margin } from '@npm/core/ui/components/atoms/common';
import { VALIDATION_RULES } from '@npm/core/ui/components/atoms/FormItem';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  DATE_FORMATS,
  parseDateToString,
  parseStringToDate,
} from '@npm/core/ui/utils/formatters';
import {
  type AssetAggregate,
  type CodebookIndex,
  type CodebookItem,
  type Holding,
  type IssuerEntitiesHoldingCreateRequestContract,
  CbAssetType,
  Codebooks,
  useCodebook,
} from '@npm/data-access';

import { getAssetTypeFromStockClassValue } from '../../filters/smartSelects/AssetTypesSelect/AssetTypesSelect.utils';

import { VISIBLE_FIELDS } from './HoldingForm.constants';
import {
  type HoldingAssetTypeKey,
  type HoldingCreateForm,
  type HoldingCreateFormKey,
  type HoldingFormItemConfig,
} from './HoldingForm.types';

export const shouldRenderField = (
  assetType: HoldingAssetTypeKey,
  field: keyof HoldingCreateForm
) => {
  return VISIBLE_FIELDS[assetType]?.find(f => f === field);
};

export const parseDateForApi = (date?: Date) => {
  return date ? parseDateToString(date, DATE_FORMATS.DATE_NUMERIC) : null;
};

export const getFormItemConfig = (
  name: keyof HoldingCreateForm,
  assetType: HoldingAssetTypeKey,
  holdingItemConfigOverride?: HoldingFormItemConfig
) => {
  const config: Partial<
    Record<
      HoldingCreateFormKey,
      Partial<Record<HoldingAssetTypeKey | 'default', HoldingFormItemConfig>>
    >
  > = {
    account_id: {
      default: {
        label: 'Account',
        rules: [{ required: true, message: 'This field is required' }],
        placeholder: 'Search',
      },
    },
    issuer_entity_id: {
      default: {
        label: 'Company',
        rules: [{ required: true, message: 'This field is required' }],
        placeholder: 'Search',
      },
    },
    type: {
      default: {
        rules: [VALIDATION_RULES.required()],
        label: 'Asset Type',
        placeholder: 'Select',
      },
    },
    underlying_holding_type: {
      default: {
        rules: [VALIDATION_RULES.required()],
        label: 'What is the underlying holding?',
        placeholder: 'Select',
      },
    },
    underlying_holding_description: {
      default: {
        rules: [VALIDATION_RULES.required()],
        label: 'Describe the blend of assets held in the SPV',
        placeholder: 'Common, Preferred, and convertible note',
      },
    },
    quantity: {
      default: {
        rules: [{ required: true, message: 'This field is required' }],
        label: 'Quantity',
        isInlineLabel: true,
      },
      SingleLayerSpv: {
        label: 'Quantity Held in SPV',
      },
      DoubleLayerSpv: {
        label: 'Quantity Held in SPV',
      },
    },
    vested_qty: {
      default: {
        rules: [{ required: true, message: 'This field is required' }],
        label: 'Vested Quantity',
        isInlineLabel: true,
      },
    },
    filled_qty: {
      default: {
        rules: [{ required: true, message: 'This field is required' }],
        label: 'Filled QTY',
        isInlineLabel: true,
      },
    },
    cost_basis: {
      default: {
        label: 'Cost Basis',
        isInlineLabel: true,
        required: false,
      },
    },
    strike_price: {
      default: {
        rules: [{ required: true, message: 'This field is required' }],
        label: 'Strike Price',
        isInlineLabel: true,
      },
    },
    certificate_number: {
      default: {
        label: 'Certificate ID',
        placeholder: `${
          assetType && CbAssetType.getShortLabel(assetType)
        }-99999`,
        rules: [],
        isInlineLabel: true,
      },
      RestrictedStockUnit: {
        label: 'Grant ID',
        addOptionalSuffix: true,
        isInlineLabel: true,
      },
      Option: {
        label: 'Grant ID',
        addOptionalSuffix: true,
        isInlineLabel: true,
      },
      Warrant: {
        label: 'Grant ID',
        addOptionalSuffix: true,
        isInlineLabel: true,
      },
      SingleLayerSpv: {
        label: 'Certificate ID',
        addOptionalSuffix: true,
        isInlineLabel: true,
      },
      DoubleLayerSpv: {
        label: 'Certificate ID',
        addOptionalSuffix: true,
        isInlineLabel: true,
      },
    },
    series: {
      default: {
        rules: [VALIDATION_RULES.required()],
        label: 'Series',
        tooltip:
          'Please enter share Series if known. If not known, you can enter N/A',
        placeholder: 'Series',
      },
      CommonStock: {
        label: 'Class',
        tooltip:
          'Please enter share Class if known. If not known, you can enter N/A',
        placeholder: 'Class',
      },
      Unit: {
        label: 'Class',
        tooltip:
          'Please enter share Class if known. If not known, you can enter N/A',
        placeholder: 'Class',
      },
      RestrictedStockUnit: {
        label: 'Class',
        tooltip:
          'Please enter share Class if known. If not known, you can enter N/A',
        placeholder: 'Class',
      },
    },
    registered_name: {
      default: {
        rules: [VALIDATION_RULES.required()],
        label: 'Registered Name',
        placeholder: 'Registered Name',
        isInlineLabel: true,
      },
    },
    plan: {
      default: {
        label: 'Plan',
        placeholder: 'Plan',
        addOptionalSuffix: true,
        isInlineLabel: true,
      },
    },
    grant_type: {
      default: {
        rules: [VALIDATION_RULES.required('Grant Type field')],
        label: 'Grant Type',
        placeholder: 'Select',
      },
    },
    acquisition_date: {
      default: {
        rules: [],
        label: 'Acquisition Date',
        addOptionalSuffix: true,
        isInlineLabel: true,
      },
    },
    grant_date: {
      default: {
        rules: [],
        label: 'Grant Date',
        addOptionalSuffix: true,
        isInlineLabel: true,
      },
    },
    expiration_date: {
      default: {
        label: 'Expiration Date',
        addOptionalSuffix: true,
        isInlineLabel: true,
      },
    },
    proof_of_ownership_document: {
      default: {
        rules: [],
      },
    },
  };

  return {
    ...config[name]['default'],
    ...config[name][assetType],
    ...holdingItemConfigOverride,
  };
};

export const prepareCreateRequestParams = ({
  id,
  type,
  grant_type,
  series,
  plan,
  strike_price,
  acquisition_date,
  grant_date,
  expiration_date,
  underlying_holding_type,
  quantity,
  vested_qty,
  ...restFields
}: HoldingCreateForm): IssuerEntitiesHoldingCreateRequestContract => ({
  asset: {
    type: getAssetTypeFromStockClassValue(type),
    series,
    plan,
    strike_price,
  },
  grant_type,
  quantity,
  vested_qty: vested_qty ?? quantity,
  attest_accuracy: true,
  ...(underlying_holding_type !== undefined && {
    underlying_holding_type: getAssetTypeFromStockClassValue(
      underlying_holding_type
    ),
  }),
  acquisition_date: parseDateForApi(acquisition_date),
  expiration_date: parseDateForApi(expiration_date),
  grant_date: parseDateForApi(grant_date),
  ...restFields,
});

export const getInitialFormData = (
  data: Holding,
  initialFilledQty?: number
): Partial<HoldingCreateForm> =>
  data && {
    ...data,
    type: data.asset.type?.code,
    remaining_quantity: data.remaining_quantity,
    underlying_holding_type: data.underlying_holding_type?.code,
    series: data.asset.series,
    plan: data.asset.plan,
    strike_price: data.asset.strike_price,
    grant_type: data.grant_type?.code,
    acquisition_date:
      data.acquisition_date && parseStringToDate(data.acquisition_date),
    expiration_date:
      data.expiration_date && parseStringToDate(data.expiration_date),
    grant_date: data.grant_date && parseStringToDate(data.grant_date),
    registered_name: data.registered_name,
    issuer_entity_id: data.issuer_entity?.id,
    filled_qty: initialFilledQty,
    state: data.state?.code,
  };

export const getHoldingFormLabel = (
  label?: string,
  optional?: boolean,
  returnType: 'plain-text' | 'react-component' = 'plain-text',
  tooltip?: string
) => {
  if (!label) return null;

  return returnType === 'plain-text' ? (
    optional ? (
      `${label} (Optional)`
    ) : (
      label
    )
  ) : (
    <>
      {label}{' '}
      {optional && (
        <Margin left="xs">
          <Text as="span" size="sm">
            (Optional)
          </Text>
        </Margin>
      )}
      {tooltip && (
        <Margin left="xs">
          <Tooltip title={tooltip}>
            <InfoIconWrap>
              <Icon name="info-circle" size="xs" />
            </InfoIconWrap>
          </Tooltip>
        </Margin>
      )}
    </>
  );
};

export const getCorrectionModeHoldingItemConfig = (
  isCorrectionMode: boolean
): HoldingFormItemConfig => {
  return isCorrectionMode
    ? {
        rules: [VALIDATION_RULES.required()],
        addOptionalSuffix: false,
        required: true,
      }
    : undefined;
};

export const getCorrectionModeQuantityConfig = ({
  isCorrectionMode,
  min,
  isVestedQuantity,
  max,
}: {
  isCorrectionMode: boolean;
  min: number;
  isVestedQuantity?: boolean;
  max?: number;
}): HoldingFormItemConfig => {
  return isCorrectionMode
    ? {
        rules: [
          VALIDATION_RULES.numberMin(
            min,
            `The ${
              isVestedQuantity ? 'Vested ' : ''
            }Quantity of the holding cannot be reduced.`
          ),
        ],
      }
    : max
    ? {
        rules: [
          VALIDATION_RULES.numberMax(
            max,
            `The ${
              isVestedQuantity ? 'Vested ' : 'Filled '
            }Quantity must be less than or equal to the ${
              isVestedQuantity ? 'Holding' : 'Vested'
            } and Remaining quantity`
          ),
        ],
      }
    : undefined;
};

export const useHoldingMapper = () => {
  const { data: codebookAssetTypeData } = useCodebook({
    type: Codebooks.ASSET_TYPE,
  });

  const { data: codebookHoldingStateData } = useCodebook({
    type: Codebooks.HOLDING_STATE,
  });

  const { data: codebookGrantTypeData } = useCodebook({
    type: Codebooks.GRANT_TYPE,
  });

  const getNameFromCodebook = (codebookData?: CodebookIndex, code?: string) => {
    return codebookData?.codebooks?.find(c => c.code === code)?.name || '-';
  };

  const mapHoldingFormValuesToHolding = (
    holdingUnmapped: HoldingCreateForm
  ): Holding => {
    return {
      ...holdingUnmapped,
      registered_name: holdingUnmapped.registered_name || '',
      attest_accuracy: true,
      grant_date: parseDateForApi(holdingUnmapped.grant_date) || undefined,
      expiration_date: parseDateForApi(holdingUnmapped.expiration_date),
      acquisition_date: parseDateForApi(holdingUnmapped.acquisition_date),
      asset: {
        plan: holdingUnmapped.plan,
        series: holdingUnmapped.series,
        strike_price: holdingUnmapped.strike_price?.toString(),
        type: {
          code: holdingUnmapped.type,
          book: Codebooks.ASSET_TYPE,
          name: getNameFromCodebook(
            codebookAssetTypeData,
            holdingUnmapped.type
          ),
        } as CodebookItem,
      } as AssetAggregate,

      underlying_holding_type: {
        code: holdingUnmapped.underlying_holding_type,
        book: Codebooks.ASSET_TYPE,
        name: getNameFromCodebook(
          codebookAssetTypeData,
          holdingUnmapped.underlying_holding_type
        ),
      } as CodebookItem,

      state: {
        code: holdingUnmapped.state,
        book: Codebooks.HOLDING_STATE,
        name: getNameFromCodebook(
          codebookHoldingStateData,
          holdingUnmapped.state
        ),
      } as CodebookItem,

      grant_type: {
        code: holdingUnmapped.grant_type,
        book: Codebooks.GRANT_TYPE,
        name: getNameFromCodebook(
          codebookGrantTypeData,
          holdingUnmapped.grant_type
        ),
      } as CodebookItem,
    };
  };

  return { mapHoldingFormValuesToHolding };
};
