import React, { useEffect, useState } from 'react';
import { type FormInstance } from 'antd';
import { addDays, compareDesc } from 'date-fns';
import { min } from 'lodash';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { FileUpload } from '@npm/core/ui/components/atoms/FileUpload';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import {
  DatePickerInput,
  InlineInputNumber,
  Input,
} from '@npm/core/ui/components/atoms/Input';
import { Select } from '@npm/core/ui/components/atoms/Select';
import { TooltipInfoIcon } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type useCollapsibleFormSection,
  CollapsibleFormSection,
} from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  type Holding,
  CbAssetType,
  CbGrantType,
  useCodebook,
} from '@npm/data-access';

import { AccountPanel } from '../../account/components/AccountPanel';
import { useCurrentBrokerageFirmId } from '../../auth/user/role';
import {
  AssetTypesSelect,
  BrokerageAccountSearch,
  CompanySearch,
  UserRoleSearch,
} from '../../filters';
import { getAssetTypeFromStockClassValue } from '../../filters/smartSelects/AssetTypesSelect/AssetTypesSelect.utils';
import { isSpvHolding } from '../Holdings.utils';

import {
  CollapsibleFormItemWrapper,
  HoldingsFormItem,
} from './HoldingsFormItem/HoldingsFormItem';
import { type HoldingCreateForm } from './HoldingForm.types';
import {
  getCorrectionModeHoldingItemConfig,
  getCorrectionModeQuantityConfig,
  getInitialFormData,
} from './HoldingForm.utils';
import { HoldingFormItemFilledQty } from './HoldingFormItemFilledQty';

import * as S from './HoldingForm.styles';

export { CollapsibleFormSection };

type Props = {
  form: FormInstance<HoldingCreateForm>;
  initialData?: Holding;
  initialFilledQty?: number;
  onValuesChange: () => void;
  preselectedValues?: Holding;
  hideIssuerEntitySelect?: boolean;
  disabledIssuerEntitySelect?: boolean;
  disabledAccountSelect?: boolean;
  showAccountSectionTitle?: boolean;
  currency?: string;
  collapsibleSectionArgs: ReturnType<typeof useCollapsibleFormSection>;
  onAccountSwitch?: () => void;
  isCorrectionMode?: boolean;
  isDraft?: boolean;
  renderFilledQuantityField?: boolean;
};

export const HoldingForm = ({
  form,
  initialData,
  initialFilledQty,
  onValuesChange,
  preselectedValues,
  hideIssuerEntitySelect,
  disabledIssuerEntitySelect,
  disabledAccountSelect,
  showAccountSectionTitle,
  currency,
  collapsibleSectionArgs: { activeKeys, handleItemChange, handleSectionChange },
  onAccountSwitch,
  isCorrectionMode = false,
  isDraft,
  renderFilledQuantityField,
}: Props) => {
  const [vestedQtyIsTouchedByUser, setVestedQtyIsTouchedByUser] = useState(
    initialData?.vested_qty !== undefined
  );

  const { data: grantTypes, isLoading: grantTypesLoading } = useCodebook({
    type: CbGrantType.code,
  });

  const brokerageFirmId = useCurrentBrokerageFirmId();

  const [selectedAccountName, setSelectedAccountName] = useState('');
  const [forcedStockClass, setForcedStockClass] = useState<string>();

  const assetType = getAssetTypeFromStockClassValue(
    Form.useWatch('type', form)
  );
  const isSpv = isSpvHolding(assetType);

  const underlyingHoldingType = getAssetTypeFromStockClassValue(
    Form.useWatch('underlying_holding_type', form)
  );

  const accountId = Form.useWatch('account_id', form);
  const issuerEntityId = Form.useWatch('issuer_entity_id', form);

  const handleValuesChange = (changedValues: HoldingCreateForm) => {
    // mirror quantity into vested_qty
    if (changedValues.quantity && !vestedQtyIsTouchedByUser) {
      form.setFieldsValue({ vested_qty: changedValues.quantity });
    }
    if (changedValues.vested_qty) {
      setVestedQtyIsTouchedByUser(true);
    }
    onValuesChange();
  };

  useEffect(() => {
    // if you can select account, update registered name accordingly
    if (!disabledAccountSelect) {
      form.setFieldsValue({
        registered_name: selectedAccountName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountName]);

  // clear form if unmounted
  useEffect(() => form?.resetFields(), [form]);

  const handleInputItemChange = (
    e: React.KeyboardEvent<HTMLInputElement>,
    name: string
  ) => {
    // inputs need to be closed only when user hits enter
    if (e.key === 'Enter') {
      handleItemChange(name);
    }
  };

  const accountPanel = (
    <AccountPanel
      showTitle={false}
      titleColor="general"
      accountName={preselectedValues?.account?.name}
      accountExternalId={preselectedValues?.account?.external_id}
      brokerageFirmName={preselectedValues?.account?.brokerage_firm?.name}
      onAccountSwitch={onAccountSwitch}
    />
  );

  const quantity = Form.useWatch('quantity', form);
  const vestedQty = Form.useWatch('vested_qty', form);
  const remainingQuantity = Form.useWatch('remaining_quantity', form);

  return (
    <Form
      form={form}
      data-cy={CypressDataIds.Holdings.Form.Name}
      initialValues={getInitialFormData(initialData, initialFilledQty)}
      onValuesChange={handleValuesChange}
    >
      <S.Container>
        <FormItem name="id" hidden />
        <FormItem name="state" hidden />
        <FormItem name="remaining_quantity" hidden />

        <HoldingsFormItem
          name="issuer_entity_id"
          initialValue={preselectedValues?.issuer_entity?.id}
          hidden={hideIssuerEntitySelect}
        >
          <CompanySearch
            variables={{ unverifiedHoldings: true }}
            disabled={disabledIssuerEntitySelect}
            defaultEntity={preselectedValues?.issuer_entity}
            data-cy={CypressDataIds.Holdings.Form.CompanySearch}
          />
        </HoldingsFormItem>

        {disabledAccountSelect && (
          <HoldingsFormItem
            name="account_id"
            label={null}
            initialValue={preselectedValues?.account?.id}
          >
            {showAccountSectionTitle ? (
              <DrawerSection
                title="Account"
                iconName="user"
                content={accountPanel}
              />
            ) : (
              accountPanel
            )}
          </HoldingsFormItem>
        )}

        {!disabledAccountSelect && (
          <HoldingsFormItem
            name="account_id"
            initialValue={preselectedValues?.account?.id}
          >
            {brokerageFirmId ? (
              <BrokerageAccountSearch
                brokerageFirmId={brokerageFirmId}
                defaultAccount={preselectedValues?.account}
                onItemChange={v => {
                  setSelectedAccountName(v?.name);
                }}
              />
            ) : (
              <UserRoleSearch
                defaultUserRole={
                  preselectedValues?.account && {
                    // this solves inconsistencies between account types
                    id: preselectedValues?.account?.id,
                    subject: {
                      brokerage_firm_name:
                        preselectedValues?.account?.brokerage_firm?.name,
                      ...preselectedValues?.account,
                    },
                  }
                }
                onItemChange={v => {
                  setSelectedAccountName(v?.subject.name);
                }}
              />
            )}
          </HoldingsFormItem>
        )}

        {issuerEntityId && accountId && (
          <FormItem>
            <HoldingsFormItem name="type">
              <AssetTypesSelect
                name="type"
                issuerEntityId={issuerEntityId}
                setForcedStockClass={setForcedStockClass}
                form={form}
                filterOptions={codebookItem =>
                  codebookItem.code !== CbAssetType.items.Blend
                }
                disabled={!isDraft && !!preselectedValues?.id}
              />
            </HoldingsFormItem>
          </FormItem>
        )}

        {isSpv && (
          <FormItem>
            <HoldingsFormItem name="underlying_holding_type">
              <AssetTypesSelect
                name="underlying_holding_type"
                issuerEntityId={issuerEntityId}
                setForcedStockClass={setForcedStockClass}
                form={form}
                filterOptions={codebookItem =>
                  codebookItem.code !== CbAssetType.items.SingleLayerSpv &&
                  codebookItem.code !== CbAssetType.items.DoubleLayerSpv
                }
              />
            </HoldingsFormItem>
          </FormItem>
        )}

        {underlyingHoldingType === CbAssetType.items.Blend && (
          <FormItem>
            <HoldingsFormItem name="underlying_holding_description">
              <Input />
            </HoldingsFormItem>
          </FormItem>
        )}

        <FormItem shouldUpdate={(curr, next) => curr.type !== next.type}>
          {assetType && (!isSpv || underlyingHoldingType) && (
            // key resets error states on asset type change
            <span key={assetType}>
              <HoldingsFormItem
                name="series"
                assetType={isSpv ? underlyingHoldingType : assetType}
              >
                <Input
                  disabled={
                    !isDraft && (!!forcedStockClass || !!preselectedValues?.id)
                  }
                />
              </HoldingsFormItem>
              <HoldingsFormItem
                name="quantity"
                assetType={assetType}
                holdingItemConfigOverride={getCorrectionModeQuantityConfig({
                  isCorrectionMode,
                  min: initialData?.quantity || 0,
                })}
              >
                <InlineInputNumber
                  min={1}
                  inputMode="decimal"
                  disabled={
                    !isDraft && !isCorrectionMode && !!preselectedValues?.id
                  }
                />
              </HoldingsFormItem>
              <HoldingsFormItem
                name="strike_price"
                assetType={assetType}
                holdingItemConfigOverride={getCorrectionModeHoldingItemConfig(
                  isCorrectionMode
                )}
              >
                <InlineInputNumber min={0} currency={currency} />
              </HoldingsFormItem>
              <HoldingsFormItem
                name="cost_basis"
                assetType={assetType}
                holdingItemConfigOverride={getCorrectionModeHoldingItemConfig(
                  isCorrectionMode
                )}
              >
                <InlineInputNumber min={0} currency={currency} />
              </HoldingsFormItem>

              <HoldingsFormItem
                name="grant_type"
                assetType={assetType}
                holdingItemConfigOverride={getCorrectionModeHoldingItemConfig(
                  isCorrectionMode
                )}
              >
                <Select
                  loading={grantTypesLoading}
                  options={grantTypes?.codebooks.map(({ name, code }) => ({
                    label: name,
                    value: code,
                  }))}
                  disabled={
                    !isDraft && !isCorrectionMode && !!preselectedValues?.id
                  }
                />
              </HoldingsFormItem>
              <Margin top="sm">
                <Margin bottom="xs">
                  <Flex align="center">
                    <Text size="sm" color="info" colorVariant="primary">
                      {isSpv ? 'Subscription Agreement' : 'Proof of Ownership'}
                    </Text>
                    <TooltipInfoIcon title="You can find your Certificates, Options Grants, and/or Awards by logging in to your cap-table management tool. " />
                  </Flex>
                </Margin>
                <Text size="xs" colorVariant="secondary" marginBottom="sm">
                  The file must be PDF, JPG or PNG. A document issued by the
                  issuer providing ownership of the holding.
                </Text>
                <HoldingsFormItem name="proof_of_ownership_document">
                  <FileUpload
                    resetOnDelete={isDraft}
                    initialFile={
                      initialData?.proof_of_ownership_document && {
                        name:
                          initialData?.proof_of_ownership_document
                            ?.display_name ||
                          initialData?.proof_of_ownership_document?.name,
                        uid: initialData?.proof_of_ownership_document?.id,
                      }
                    }
                    label={'Upload file (Max: 1)'}
                    onChange={() =>
                      handleItemChange('proof_of_ownership_document')
                    }
                  />
                </HoldingsFormItem>
              </Margin>

              <CollapsibleFormSection
                activeKey={activeKeys}
                onChange={handleSectionChange}
              >
                <CollapsibleFormItemWrapper key="certificate_number">
                  <HoldingsFormItem
                    name="certificate_number"
                    assetType={assetType}
                    holdingItemConfigOverride={getCorrectionModeHoldingItemConfig(
                      isCorrectionMode
                    )}
                  >
                    <Input
                      onKeyUp={e =>
                        handleInputItemChange(e, 'certificate_number')
                      }
                    />
                  </HoldingsFormItem>
                </CollapsibleFormItemWrapper>
                <CollapsibleFormItemWrapper key="vested_qty">
                  <HoldingsFormItem
                    name="vested_qty"
                    assetType={assetType}
                    holdingItemConfigOverride={getCorrectionModeQuantityConfig({
                      isCorrectionMode,
                      min: initialData?.vested_qty || 0,
                      isVestedQuantity: true,
                      max: quantity,
                    })}
                  >
                    <InlineInputNumber
                      min={0}
                      inputMode="decimal"
                      onKeyUp={e => handleInputItemChange(e, 'vested_qty')}
                      disabled={
                        !isDraft && !isCorrectionMode && !!preselectedValues?.id
                      }
                    />
                  </HoldingsFormItem>
                </CollapsibleFormItemWrapper>

                <CollapsibleFormItemWrapper key="plan">
                  <HoldingsFormItem
                    name="plan"
                    assetType={isSpv ? underlyingHoldingType : assetType}
                  >
                    <Input />
                  </HoldingsFormItem>
                </CollapsibleFormItemWrapper>

                <CollapsibleFormItemWrapper key="registered_name">
                  <HoldingsFormItem
                    name="registered_name"
                    initialValue={
                      preselectedValues?.account?.name ||
                      preselectedValues?.registered_name
                    }
                  >
                    <Input
                      onKeyUp={e => handleInputItemChange(e, 'registered_name')}
                      disabled={!isDraft && !!preselectedValues?.id}
                    />
                  </HoldingsFormItem>
                </CollapsibleFormItemWrapper>

                <CollapsibleFormItemWrapper
                  key="acquisition_date"
                  placeholder="Select Date"
                >
                  <HoldingsFormItem
                    name="acquisition_date"
                    assetType={assetType}
                    holdingItemConfigOverride={getCorrectionModeHoldingItemConfig(
                      isCorrectionMode
                    )}
                  >
                    <DatePickerInput
                      allowClear
                      disabledDate={(date: Date) => date.getTime() > Date.now()}
                      onChange={() => handleItemChange('acquisition_date')}
                      disabled={
                        !isDraft && !isCorrectionMode && !!preselectedValues?.id
                      }
                    />
                  </HoldingsFormItem>
                </CollapsibleFormItemWrapper>
                <CollapsibleFormItemWrapper
                  key="grant_date"
                  placeholder="Select Date"
                >
                  <HoldingsFormItem
                    name="grant_date"
                    assetType={assetType}
                    holdingItemConfigOverride={getCorrectionModeHoldingItemConfig(
                      isCorrectionMode
                    )}
                  >
                    <DatePickerInput
                      allowClear
                      disabledDate={(date: Date) => date.getTime() > Date.now()}
                      onChange={() => handleItemChange('grant_date')}
                    />
                  </HoldingsFormItem>
                </CollapsibleFormItemWrapper>
                <CollapsibleFormItemWrapper
                  key="expiration_date"
                  placeholder="Select Date"
                >
                  <HoldingsFormItem
                    name="expiration_date"
                    assetType={assetType}
                  >
                    <DatePickerInput
                      allowClear
                      disabledDate={(date: Date) =>
                        compareDesc(date, addDays(new Date(), -1)) === 1
                      }
                      onChange={() => handleItemChange('expiration_date')}
                    />
                  </HoldingsFormItem>
                </CollapsibleFormItemWrapper>
              </CollapsibleFormSection>

              {renderFilledQuantityField && (
                <HoldingFormItemFilledQty
                  maxFilledQuantity={min([
                    quantity,
                    vestedQty,
                    remainingQuantity,
                  ])}
                  initialFilledQty={initialFilledQty}
                />
              )}
            </span>
          )}
        </FormItem>
      </S.Container>
    </Form>
  );
};
