import { Codebooks } from './codebooks.types';

const Items = {
  live_programs: 'program',
  secondmarket: 'secondmarket',
  t_and_s: 't_and_s',
  mp: 'mp', // Marketplace
} as const;

export const CbMatchSource = {
  code: Codebooks.MATCH_SOURCE,
  items: Items,
};
