import styled from 'styled-components';

import { centerFlex } from '@npm/core/ui/utils/style';

export const Container = styled.div`
  ${centerFlex}
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}px;
  margin: 0;
  color: ${({ theme }) => theme.color.general.typography.tertiary};
  background-color: ${({ theme }) => theme.color.general.layout.zero};
  font-size: ${({ theme }) => theme.typography.size.xxs}px;
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  border-bottom: none;
  padding: ${({ theme }) => theme.spacing.sm}px;

  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.xs}px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm}px;
  margin: 0;
  color: ${({ theme }) => theme.color.info.typography.primary};
  background-color: ${({ theme }) =>
    theme.color.info.backgrounds.secondaryHover};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.xs}px;
`;

export const Divider = styled.div`
  flex: 0 0 1px;
  background-color: ${({ theme }) => theme.color.general.borders.secondary};
`;

export const IconContainer = styled.div`
  ${centerFlex}
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  padding: ${({ theme }) => theme.spacing.xs}px;
  background: ${({ theme }) => theme.color.info.backgrounds.primaryDefault};
  margin-left: ${({ theme }) => theme.spacing.xs}px;
  margin-right: ${({ theme }) => theme.spacing.xs}px;

  > svg {
    color: ${({ theme }) => theme.color.general.typography.negative};
  }
`;
