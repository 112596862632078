import { type AxiosInstance } from 'axios';

import { eventBus } from '@npm/core/ui/utils/eventBus';

import { TOS_URL_ROUTE, TOS_URL_STORAGE_KEY } from '../constants';

const TOS_URL = `${window.location.origin}${TOS_URL_ROUTE}`;

export const initTOSHandling = (axios: AxiosInstance) => {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      if (
        error?.response?.status === 441 &&
        window.location.href !== TOS_URL &&
        window.location.href !== `${TOS_URL}/plain`
      ) {
        window.localStorage.setItem(TOS_URL_STORAGE_KEY, window.location.href);
        if (
          !eventBus.dispatch('HISTORY_EVENT', {
            type: 'replace',
            location: TOS_URL_ROUTE,
          })
        ) {
          window.location.href = TOS_URL;
        }
      }
      return Promise.reject(error);
    }
  );
};
