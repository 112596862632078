import React from 'react';

import { Icon } from '../../../../atoms/Icon';
import { Heading } from '../../../../atoms/Typography';
import { useHistory } from '../../../../molecules/Link';

import * as S from '../WizardLayout.styles';

type Props = {
  title: React.ReactNode;
  exitRoute: string;
};

export const HeaderUncollapsable = ({ title, exitRoute }: Props) => {
  const history = useHistory();

  return (
    <S.Header>
      <S.ExitButton
        variant="text"
        color="info"
        icon={<Icon name="chevron-left" size="xs" />}
        onClick={() => history.push(exitRoute)}
      >
        Leave
      </S.ExitButton>

      <S.TitleWrapperUncollapsable>
        <Heading variant="h3" as="h1">
          {title}
        </Heading>
      </S.TitleWrapperUncollapsable>
    </S.Header>
  );
};
