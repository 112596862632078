import React from 'react';

import { Helmet } from '@npm/core/ui/components/atoms/Helmet';
import { HeaderUncollapsable } from '@npm/core/ui/components/organisms/WizardNew/Layout/Header';
import { type issuerWorkstationRouteKeys } from '@npm/core/ui/routes';
import { type RouteTitleProps } from '@npm/core/ui/routes/router.types';

export const MatchReportPageTitle = (props: RouteTitleProps) => {
  return (
    <>
      <Helmet pageTitle="Report a Trade"></Helmet>
      <HeaderUncollapsable
        title="Report a Trade"
        exitRoute={
          '/issuer-workstation/marketplace/matches' satisfies issuerWorkstationRouteKeys
        }
      />
    </>
  );
};
