import { useMemo } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useWatchlistCreate } from '@npm/data-access';

import { useObo } from '../../../../../features/src/auth/user/role';
import {
  isEntityAccount,
  isNPMS,
  useUserContextStore,
} from '../../../auth/user/context';
import { useOrderPlacementDrawerContext } from '../../../second-market/drawers/OrderEntryDrawer';
import { isUserRolePermittedToAddToWatchlist } from '../../../watchlist';

import * as S from './DisabledTradingContent.styles';

export const DisabledTradingContent = ({
  companyId,
}: {
  companyId: number;
}) => {
  const [{ handlePlaceOrder }] = useOrderPlacementDrawerContext();
  const { isObo, oboAccountId } = useObo();
  const { execute: addToWatchlist } = useWatchlistCreate();
  const { withShowApiErrorMiddleware } = useAlerts();

  const investorAccounts = useUserContextStore(state => state.investorAccounts);

  const roles = useMemo(
    () =>
      investorAccounts?.filter(
        role =>
          isUserRolePermittedToAddToWatchlist(role) &&
          isNPMS(role?.subject) &&
          isEntityAccount(role)
      ),
    [investorAccounts]
  );

  const onAddToWatchlist = async () => {
    const defaultAccountId = isObo ? oboAccountId : roles?.[0]?.subject?.id;

    try {
      await withShowApiErrorMiddleware(addToWatchlist)({
        watchlistCreateRequestContract: {
          issuer_entity_id: [companyId],
          account_id: defaultAccountId,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <S.Container>
      <Icon name="arrows-exchange-vertical" colorVariant="muted" size="xxs" />
      This company is not allowing transfers at this time
      <S.ActionsContainer>
        <Button variant="text" onClick={() => handlePlaceOrder()} size="sm">
          <S.IconContainer>
            <Icon name="arrows-opposite" size="sm" />
          </S.IconContainer>
          Submit an IOI
        </Button>
        <S.Divider />
        <Button variant="text" onClick={() => onAddToWatchlist()} size="sm">
          Get Notified
          <S.IconContainer>
            <Icon name="star" size="sm" />
          </S.IconContainer>
        </Button>
      </S.ActionsContainer>
    </S.Container>
  );
};
