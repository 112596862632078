import React, { type ComponentProps } from 'react';

import { type Alert } from '../Alert';
import { Icon } from '../Icon';
import { Text } from '../Typography';

import * as S from './RuleAlert.styles';

type Props = Pick<ComponentProps<typeof Alert>, 'type' | 'message' | 'icon'>;

export const RuleAlert = ({
  type,
  message,
  icon = <Icon name="warning" size="xs" />,
}: Props) => {
  return (
    <S.RuleAlert
      type={type}
      message={
        <Text size="xs" color={type === 'info' ? 'general' : type}>
          {message}
        </Text>
      }
      icon={icon}
      showIcon={icon !== null}
    />
  );
};
