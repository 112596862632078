import styled from 'styled-components';

import { Tabs as TabsBase } from '@npm/core/ui/components/atoms/Tabs';
import { Tooltip as TooltipBase } from '@npm/core/ui/components/atoms/Tooltip';
import { centerFlex } from '@npm/core/ui/utils/style';

export const Tabs = styled(TabsBase)`
  .ant-tabs-tab {
    .ant-tabs-tab-btn,
    .ant-tabs-tab-btn:hover {
      padding: 0 !important;
    }
  }
`;

export const Tooltip = styled(TooltipBase)`
  ${centerFlex}
  width: 100%;
  height: 100%;
`;
