import { Codebooks } from './codebooks.types';

const Items = {
  AnyShareClass: 'AnyShareClass',
  CommonStock: 'CommonStock',
  Option: 'Option',
  PreferredStock: 'PreferredStock',
  RestrictedStockUnit: 'RestrictedStockUnit',
  Unit: 'Unit',
  Warrant: 'Warrant',
  SingleLayerSpv: 'SingleLayerSpv',
  DoubleLayerSpv: 'DoubleLayerSpv',
  Blend: 'Blend',
  Multiple: 'Multiple',
} as const;

const itemToLabel = {
  AnyShareClass: 'Any Share Class',
  CommonStock: 'Common',
  Option: 'Option',
  PreferredStock: 'Preferred',
  RestrictedStockUnit: 'Restricted Stock Unit',
  Unit: 'Unit',
  Warrant: 'Warrant',
  SingleLayerSpv: 'Single Layer SPV',
  DoubleLayerSpv: 'Double Layer SPV',
  Blend: 'Blend of Assets',
  Multiple: 'Multiple',
} as const;

export type MatchActivityAssetTypeCode = keyof typeof Items;

export const CbMatchActivityAssetType = {
  code: Codebooks.ASSET_TYPE,
  items: Items,
  getLabel: (code: MatchActivityAssetTypeCode) => itemToLabel[code],
};
