/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  PersonAccreditationApi,
  PersonAccreditationCreateErrorCodes,
  PersonAccreditationShowErrorCodes,
  PersonAccreditationShowErrorTypes,
  PersonAccreditationDeleteErrorCodes,
  PersonAccreditationShowByIdErrorCodes,
  PersonAccreditationShowByIdErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new PersonAccreditationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type PersonAccreditationCreateMethod = PersonAccreditationApi['personAccreditationCreate'];

export const usePersonAccreditationCreate = () => {
  return useMutation<PersonAccreditationCreateMethod>(
    (...args) => api.personAccreditationCreate(...args),
    PersonAccreditationCreateErrorCodes,
    'PersonAccreditationCreate',
    '/api/persons/{person_id}/accreditation',
    'post'
  );
};

export type PersonAccreditationShowMethod = PersonAccreditationApi['personAccreditationShow'];
export type PersonAccreditationShowResponseType = MethodResult<PersonAccreditationShowMethod>;

export const usePersonAccreditationShow = (
  variables: Parameters<PersonAccreditationShowMethod>[0],
  config?: UseQueryConfig<
    PersonAccreditationShowMethod,
    PersonAccreditationShowErrorTypes
  >
) => {
  return useQuery<
    PersonAccreditationShowMethod,
    PersonAccreditationShowErrorTypes
  >(
    (...args) => api.personAccreditationShow(...args),
    PersonAccreditationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PersonAccreditationShow'],
        ...apiQueryConfig.PersonAccreditationShow,
        ...config?.queryConfig
      },
    },
    'PersonAccreditationShow',
  );
};

export const usePersonAccreditationShowInfinite = (
  variables: Parameters<PersonAccreditationShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    PersonAccreditationShowMethod,
    PersonAccreditationShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    PersonAccreditationShowMethod,
    PersonAccreditationShowErrorTypes
  >(
    (...args) => api.personAccreditationShow(...args),
    PersonAccreditationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PersonAccreditationShow', 'PersonAccreditationShowInfinite'],
        ...apiQueryConfig.PersonAccreditationShow,
        ...config?.queryConfig,
      },
    },
    'PersonAccreditationShow',
  );
};

export const usePersonAccreditationShowLazy = (baseOptions?: {
  variables?: Parameters<PersonAccreditationShowMethod>[0],
  config?: UseQueryConfig<
    PersonAccreditationShowMethod,
    PersonAccreditationShowErrorTypes
  >
}) => {
  return useLazyQuery<
    PersonAccreditationShowMethod,
    PersonAccreditationShowErrorTypes
  >(
    (...args) => api.personAccreditationShow(...args),
    PersonAccreditationShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['PersonAccreditationShow'],
        ...apiQueryConfig.PersonAccreditationShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'PersonAccreditationShow',
  );
};


export type PersonAccreditationDeleteMethod = PersonAccreditationApi['personAccreditationDelete'];

export const usePersonAccreditationDelete = () => {
  return useMutation<PersonAccreditationDeleteMethod>(
    (...args) => api.personAccreditationDelete(...args),
    PersonAccreditationDeleteErrorCodes,
    'PersonAccreditationDelete',
    '/api/persons/{person_id}/accreditations/{id}',
    'delete'
  );
};

export type PersonAccreditationShowByIdMethod = PersonAccreditationApi['personAccreditationShowById'];
export type PersonAccreditationShowByIdResponseType = MethodResult<PersonAccreditationShowByIdMethod>;

export const usePersonAccreditationShowById = (
  variables: Parameters<PersonAccreditationShowByIdMethod>[0],
  config?: UseQueryConfig<
    PersonAccreditationShowByIdMethod,
    PersonAccreditationShowByIdErrorTypes
  >
) => {
  return useQuery<
    PersonAccreditationShowByIdMethod,
    PersonAccreditationShowByIdErrorTypes
  >(
    (...args) => api.personAccreditationShowById(...args),
    PersonAccreditationShowByIdErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PersonAccreditationShowById'],
        ...apiQueryConfig.PersonAccreditationShowById,
        ...config?.queryConfig
      },
    },
    'PersonAccreditationShowById',
  );
};

export const usePersonAccreditationShowByIdInfinite = (
  variables: Parameters<PersonAccreditationShowByIdMethod>[0],
  config?: UseInfiniteQueryConfig<
    PersonAccreditationShowByIdMethod,
    PersonAccreditationShowByIdErrorTypes
  >
) => {
  return useInfiniteQuery<
    PersonAccreditationShowByIdMethod,
    PersonAccreditationShowByIdErrorTypes
  >(
    (...args) => api.personAccreditationShowById(...args),
    PersonAccreditationShowByIdErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PersonAccreditationShowById', 'PersonAccreditationShowByIdInfinite'],
        ...apiQueryConfig.PersonAccreditationShowById,
        ...config?.queryConfig,
      },
    },
    'PersonAccreditationShowById',
  );
};

export const usePersonAccreditationShowByIdLazy = (baseOptions?: {
  variables?: Parameters<PersonAccreditationShowByIdMethod>[0],
  config?: UseQueryConfig<
    PersonAccreditationShowByIdMethod,
    PersonAccreditationShowByIdErrorTypes
  >
}) => {
  return useLazyQuery<
    PersonAccreditationShowByIdMethod,
    PersonAccreditationShowByIdErrorTypes
  >(
    (...args) => api.personAccreditationShowById(...args),
    PersonAccreditationShowByIdErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['PersonAccreditationShowById'],
        ...apiQueryConfig.PersonAccreditationShowById,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'PersonAccreditationShowById',
  );
};

