/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  WorkstationAccountApi,
  WorkstationAccountIndexErrorCodes,
  WorkstationAccountIndexErrorTypes,
  WorkstationAccountShowErrorCodes,
  WorkstationAccountShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new WorkstationAccountApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type WorkstationAccountIndexMethod = WorkstationAccountApi['workstationAccountIndex'];
export type WorkstationAccountIndexResponseType = MethodResult<WorkstationAccountIndexMethod>;

export const useWorkstationAccountIndex = (
  variables: Parameters<WorkstationAccountIndexMethod>[0],
  config?: UseQueryConfig<
    WorkstationAccountIndexMethod,
    WorkstationAccountIndexErrorTypes
  >
) => {
  return useQuery<
    WorkstationAccountIndexMethod,
    WorkstationAccountIndexErrorTypes
  >(
    (...args) => api.workstationAccountIndex(...args),
    WorkstationAccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['WorkstationAccountIndex'],
        ...apiQueryConfig.WorkstationAccountIndex,
        ...config?.queryConfig
      },
    },
    'WorkstationAccountIndex',
  );
};

export const useWorkstationAccountIndexInfinite = (
  variables: Parameters<WorkstationAccountIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    WorkstationAccountIndexMethod,
    WorkstationAccountIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    WorkstationAccountIndexMethod,
    WorkstationAccountIndexErrorTypes
  >(
    (...args) => api.workstationAccountIndex(...args),
    WorkstationAccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['WorkstationAccountIndex', 'WorkstationAccountIndexInfinite'],
        ...apiQueryConfig.WorkstationAccountIndex,
        ...config?.queryConfig,
      },
    },
    'WorkstationAccountIndex',
  );
};

export const useWorkstationAccountIndexLazy = (baseOptions?: {
  variables?: Parameters<WorkstationAccountIndexMethod>[0],
  config?: UseQueryConfig<
    WorkstationAccountIndexMethod,
    WorkstationAccountIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    WorkstationAccountIndexMethod,
    WorkstationAccountIndexErrorTypes
  >(
    (...args) => api.workstationAccountIndex(...args),
    WorkstationAccountIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['WorkstationAccountIndex'],
        ...apiQueryConfig.WorkstationAccountIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'WorkstationAccountIndex',
  );
};


export type WorkstationAccountShowMethod = WorkstationAccountApi['workstationAccountShow'];
export type WorkstationAccountShowResponseType = MethodResult<WorkstationAccountShowMethod>;

export const useWorkstationAccountShow = (
  variables: Parameters<WorkstationAccountShowMethod>[0],
  config?: UseQueryConfig<
    WorkstationAccountShowMethod,
    WorkstationAccountShowErrorTypes
  >
) => {
  return useQuery<
    WorkstationAccountShowMethod,
    WorkstationAccountShowErrorTypes
  >(
    (...args) => api.workstationAccountShow(...args),
    WorkstationAccountShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['WorkstationAccountShow'],
        ...apiQueryConfig.WorkstationAccountShow,
        ...config?.queryConfig
      },
    },
    'WorkstationAccountShow',
  );
};

export const useWorkstationAccountShowInfinite = (
  variables: Parameters<WorkstationAccountShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    WorkstationAccountShowMethod,
    WorkstationAccountShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    WorkstationAccountShowMethod,
    WorkstationAccountShowErrorTypes
  >(
    (...args) => api.workstationAccountShow(...args),
    WorkstationAccountShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['WorkstationAccountShow', 'WorkstationAccountShowInfinite'],
        ...apiQueryConfig.WorkstationAccountShow,
        ...config?.queryConfig,
      },
    },
    'WorkstationAccountShow',
  );
};

export const useWorkstationAccountShowLazy = (baseOptions?: {
  variables?: Parameters<WorkstationAccountShowMethod>[0],
  config?: UseQueryConfig<
    WorkstationAccountShowMethod,
    WorkstationAccountShowErrorTypes
  >
}) => {
  return useLazyQuery<
    WorkstationAccountShowMethod,
    WorkstationAccountShowErrorTypes
  >(
    (...args) => api.workstationAccountShow(...args),
    WorkstationAccountShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['WorkstationAccountShow'],
        ...apiQueryConfig.WorkstationAccountShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'WorkstationAccountShow',
  );
};

