/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountCreateRequestContract } from '../model';
// @ts-ignore
import { AccountForBrokerage } from '../model';
// @ts-ignore
import { AccountIndex } from '../model';
// @ts-ignore
import { AccountShowAggregate } from '../model';
// @ts-ignore
import { AccountUpdateRequestContract } from '../model';
// @ts-ignore
import { AccountsSecondMarketAccountCopyCreateRequestContract } from '../model';
// @ts-ignore
import { BrokerageFirmsAccountCreateRequestContract } from '../model';
// @ts-ignore
import { BrokerageFirmsAccountIndex } from '../model';
// @ts-ignore
import { BrokerageFirmsAccountsStatisticShow } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
// @ts-ignore
import { HoldingsStatisticShow } from '../model';
// @ts-ignore
import { IssuerEntitiesCompanyStatisticShow } from '../model';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create/finish onboarding account
         * @param {AccountCreateRequestContract} accountCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountCreate: async (accountCreateRequestContract: AccountCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountCreateRequestContract' is not null or undefined
            assertParamExists('accountCreate', 'accountCreateRequestContract', accountCreateRequestContract)
            const localVarPath = changeUrl(`/api/accounts`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists all accounts
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {string} [lastAccessedAfter] 
         * @param {Array<string>} [accountType] 
         * @param {Array<string>} [userStatus] 
         * @param {number} [brokerageFirmId] 
         * @param {boolean} [hasActiveEvents] If set to true, accounts with at least one active event will be retuned. If set to false, accounts with no active events will be returned.
         * @param {boolean} [npmsOnly] filters accounts for NPMS brokerage firm
         * @param {string} [institutionalClient] 
         * @param {string} [backgroundCheckState] 
         * @param {boolean} [marketplaceAgreementSigned] filters whether account has signed marketplace agreement when specified
         * @param {string} [accreditationFilter] 
         * @param {Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountIndex: async (page?: number, size?: number, search?: string, lastAccessedAfter?: string, accountType?: Array<string>, userStatus?: Array<string>, brokerageFirmId?: number, hasActiveEvents?: boolean, npmsOnly?: boolean, institutionalClient?: string, backgroundCheckState?: string, marketplaceAgreementSigned?: boolean, accreditationFilter?: string, includeField?: Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/accounts`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (lastAccessedAfter !== undefined) {
                localVarQueryParameter['last_accessed_after'] = (lastAccessedAfter as any instanceof Date) ?
                    (lastAccessedAfter as any).toISOString().substr(0,10) :
                    lastAccessedAfter;
            }

            if (accountType) {
                localVarQueryParameter['account_type'] = accountType.join(COLLECTION_FORMATS.csv);
            }

            if (userStatus) {
                localVarQueryParameter['user_status'] = userStatus.join(COLLECTION_FORMATS.csv);
            }

            if (brokerageFirmId !== undefined) {
                localVarQueryParameter['brokerage_firm_id'] = brokerageFirmId;
            }

            if (hasActiveEvents !== undefined) {
                localVarQueryParameter['has_active_events'] = hasActiveEvents;
            }

            if (npmsOnly !== undefined) {
                localVarQueryParameter['npms_only'] = npmsOnly;
            }

            if (institutionalClient !== undefined) {
                localVarQueryParameter['institutional_client'] = institutionalClient;
            }

            if (backgroundCheckState !== undefined) {
                localVarQueryParameter['background_check_state'] = backgroundCheckState;
            }

            if (marketplaceAgreementSigned !== undefined) {
                localVarQueryParameter['marketplace_agreement_signed'] = marketplaceAgreementSigned;
            }

            if (accreditationFilter !== undefined) {
                localVarQueryParameter['accreditation_filter'] = accreditationFilter;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieves the details of an account
         * @param {string} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountShow: async (id: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountShow', 'id', id)
            const localVarPath = changeUrl(`/api/accounts/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates the account details
         * @param {number} id 
         * @param {AccountUpdateRequestContract} accountUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdate: async (id: number, accountUpdateRequestContract: AccountUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountUpdate', 'id', id)
            // verify required parameter 'accountUpdateRequestContract' is not null or undefined
            assertParamExists('accountUpdate', 'accountUpdateRequestContract', accountUpdateRequestContract)
            const localVarPath = changeUrl(`/api/accounts/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create an accounts in the specified brokerage firm
         * @param {number} brokerageFirmId 
         * @param {BrokerageFirmsAccountCreateRequestContract} brokerageFirmsAccountCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmAccountCreate: async (brokerageFirmId: number, brokerageFirmsAccountCreateRequestContract: BrokerageFirmsAccountCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerageFirmId' is not null or undefined
            assertParamExists('brokerageFirmAccountCreate', 'brokerageFirmId', brokerageFirmId)
            // verify required parameter 'brokerageFirmsAccountCreateRequestContract' is not null or undefined
            assertParamExists('brokerageFirmAccountCreate', 'brokerageFirmsAccountCreateRequestContract', brokerageFirmsAccountCreateRequestContract)
            const localVarPath = changeUrl(`/api/brokerage-firms/{brokerage_firm_id}/accounts`)
                .replace(`{${"brokerage_firm_id"}}`, encodeURIComponent(String(brokerageFirmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brokerageFirmsAccountCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists all accounts in the specified brokerage firm
         * @param {number} brokerageFirmId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {string} [createdAfter] 
         * @param {string} [lastAccessedAfter] 
         * @param {Array<string>} [accountType] 
         * @param {Array<string>} [accountStatus] 
         * @param {Array<number>} [assignedUserId] 
         * @param {Array<string>} [accountSponsorshipType] 
         * @param {Array<string>} [userStatus] 
         * @param {number} [organizationId] 
         * @param {string} [institutionalClient] 
         * @param {string} [backgroundCheckState] 
         * @param {boolean} [marketplaceAgreementSigned] filters whether account has signed marketplace agreement when specified
         * @param {string} [accreditationFilter] 
         * @param {string} [country] 
         * @param {boolean} [withOrders] 
         * @param {boolean} [withHoldings] 
         * @param {Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmAccountIndex: async (brokerageFirmId: number, page?: number, size?: number, search?: string, createdAfter?: string, lastAccessedAfter?: string, accountType?: Array<string>, accountStatus?: Array<string>, assignedUserId?: Array<number>, accountSponsorshipType?: Array<string>, userStatus?: Array<string>, organizationId?: number, institutionalClient?: string, backgroundCheckState?: string, marketplaceAgreementSigned?: boolean, accreditationFilter?: string, country?: string, withOrders?: boolean, withHoldings?: boolean, includeField?: Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerageFirmId' is not null or undefined
            assertParamExists('brokerageFirmAccountIndex', 'brokerageFirmId', brokerageFirmId)
            const localVarPath = changeUrl(`/api/brokerage-firms/{brokerage_firm_id}/accounts`)
                .replace(`{${"brokerage_firm_id"}}`, encodeURIComponent(String(brokerageFirmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['created_after'] = (createdAfter as any instanceof Date) ?
                    (createdAfter as any).toISOString().substr(0,10) :
                    createdAfter;
            }

            if (lastAccessedAfter !== undefined) {
                localVarQueryParameter['last_accessed_after'] = (lastAccessedAfter as any instanceof Date) ?
                    (lastAccessedAfter as any).toISOString().substr(0,10) :
                    lastAccessedAfter;
            }

            if (accountType) {
                localVarQueryParameter['account_type'] = accountType.join(COLLECTION_FORMATS.csv);
            }

            if (accountStatus) {
                localVarQueryParameter['account_status'] = accountStatus.join(COLLECTION_FORMATS.csv);
            }

            if (assignedUserId) {
                localVarQueryParameter['assigned_user_id'] = assignedUserId.join(COLLECTION_FORMATS.csv);
            }

            if (accountSponsorshipType) {
                localVarQueryParameter['account_sponsorship_type'] = accountSponsorshipType.join(COLLECTION_FORMATS.csv);
            }

            if (userStatus) {
                localVarQueryParameter['user_status'] = userStatus.join(COLLECTION_FORMATS.csv);
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (institutionalClient !== undefined) {
                localVarQueryParameter['institutional_client'] = institutionalClient;
            }

            if (backgroundCheckState !== undefined) {
                localVarQueryParameter['background_check_state'] = backgroundCheckState;
            }

            if (marketplaceAgreementSigned !== undefined) {
                localVarQueryParameter['marketplace_agreement_signed'] = marketplaceAgreementSigned;
            }

            if (accreditationFilter !== undefined) {
                localVarQueryParameter['accreditation_filter'] = accreditationFilter;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (withOrders !== undefined) {
                localVarQueryParameter['with_orders'] = withOrders;
            }

            if (withHoldings !== undefined) {
                localVarQueryParameter['with_holdings'] = withHoldings;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves account statistics associated with the brokerage firm specified in the path
         * @param {number} brokerageFirmId 
         * @param {string} accountType 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmAccountStatisticShow: async (brokerageFirmId: number, accountType: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brokerageFirmId' is not null or undefined
            assertParamExists('brokerageFirmAccountStatisticShow', 'brokerageFirmId', brokerageFirmId)
            // verify required parameter 'accountType' is not null or undefined
            assertParamExists('brokerageFirmAccountStatisticShow', 'accountType', accountType)
            const localVarPath = changeUrl(`/api/brokerage-firms/{brokerage_firm_id}/accounts/statistics`)
                .replace(`{${"brokerage_firm_id"}}`, encodeURIComponent(String(brokerageFirmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountType !== undefined) {
                localVarQueryParameter['account_type'] = accountType;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves holding statistics associated with the event specified in query params
         * @param {number} eventId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingStatisticShow: async (eventId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('holdingStatisticShow', 'eventId', eventId)
            const localVarPath = changeUrl(`/api/holdings/statistics`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (eventId !== undefined) {
                localVarQueryParameter['event_id'] = eventId;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves company statistics
         * @param {number} issuerEntityId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityCompanyStatistics: async (issuerEntityId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerEntityId' is not null or undefined
            assertParamExists('issuerEntityCompanyStatistics', 'issuerEntityId', issuerEntityId)
            const localVarPath = changeUrl(`/api/issuer-entities/{issuer_entity_id}/company-statistics`)
                .replace(`{${"issuer_entity_id"}}`, encodeURIComponent(String(issuerEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new Second Market account from the information of an NPM Settlement account.
         * @param {number} accountId 
         * @param {AccountsSecondMarketAccountCopyCreateRequestContract} accountsSecondMarketAccountCopyCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondMarketAccountCopyCreate: async (accountId: number, accountsSecondMarketAccountCopyCreateRequestContract: AccountsSecondMarketAccountCopyCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('secondMarketAccountCopyCreate', 'accountId', accountId)
            // verify required parameter 'accountsSecondMarketAccountCopyCreateRequestContract' is not null or undefined
            assertParamExists('secondMarketAccountCopyCreate', 'accountsSecondMarketAccountCopyCreateRequestContract', accountsSecondMarketAccountCopyCreateRequestContract)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/second-market-account-copy`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountsSecondMarketAccountCopyCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create/finish onboarding account
         * @param {AccountCreateRequestContract} accountCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountCreate(accountCreateRequestContract: AccountCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountCreate(accountCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists all accounts
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {string} [lastAccessedAfter] 
         * @param {Array<string>} [accountType] 
         * @param {Array<string>} [userStatus] 
         * @param {number} [brokerageFirmId] 
         * @param {boolean} [hasActiveEvents] If set to true, accounts with at least one active event will be retuned. If set to false, accounts with no active events will be returned.
         * @param {boolean} [npmsOnly] filters accounts for NPMS brokerage firm
         * @param {string} [institutionalClient] 
         * @param {string} [backgroundCheckState] 
         * @param {boolean} [marketplaceAgreementSigned] filters whether account has signed marketplace agreement when specified
         * @param {string} [accreditationFilter] 
         * @param {Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountIndex(page?: number, size?: number, search?: string, lastAccessedAfter?: string, accountType?: Array<string>, userStatus?: Array<string>, brokerageFirmId?: number, hasActiveEvents?: boolean, npmsOnly?: boolean, institutionalClient?: string, backgroundCheckState?: string, marketplaceAgreementSigned?: boolean, accreditationFilter?: string, includeField?: Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountIndex(page, size, search, lastAccessedAfter, accountType, userStatus, brokerageFirmId, hasActiveEvents, npmsOnly, institutionalClient, backgroundCheckState, marketplaceAgreementSigned, accreditationFilter, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary retrieves the details of an account
         * @param {string} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountShow(id: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates the account details
         * @param {number} id 
         * @param {AccountUpdateRequestContract} accountUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUpdate(id: number, accountUpdateRequestContract: AccountUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUpdate(id, accountUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create an accounts in the specified brokerage firm
         * @param {number} brokerageFirmId 
         * @param {BrokerageFirmsAccountCreateRequestContract} brokerageFirmsAccountCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerageFirmAccountCreate(brokerageFirmId: number, brokerageFirmsAccountCreateRequestContract: BrokerageFirmsAccountCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountForBrokerage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerageFirmAccountCreate(brokerageFirmId, brokerageFirmsAccountCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists all accounts in the specified brokerage firm
         * @param {number} brokerageFirmId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {string} [createdAfter] 
         * @param {string} [lastAccessedAfter] 
         * @param {Array<string>} [accountType] 
         * @param {Array<string>} [accountStatus] 
         * @param {Array<number>} [assignedUserId] 
         * @param {Array<string>} [accountSponsorshipType] 
         * @param {Array<string>} [userStatus] 
         * @param {number} [organizationId] 
         * @param {string} [institutionalClient] 
         * @param {string} [backgroundCheckState] 
         * @param {boolean} [marketplaceAgreementSigned] filters whether account has signed marketplace agreement when specified
         * @param {string} [accreditationFilter] 
         * @param {string} [country] 
         * @param {boolean} [withOrders] 
         * @param {boolean} [withHoldings] 
         * @param {Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerageFirmAccountIndex(brokerageFirmId: number, page?: number, size?: number, search?: string, createdAfter?: string, lastAccessedAfter?: string, accountType?: Array<string>, accountStatus?: Array<string>, assignedUserId?: Array<number>, accountSponsorshipType?: Array<string>, userStatus?: Array<string>, organizationId?: number, institutionalClient?: string, backgroundCheckState?: string, marketplaceAgreementSigned?: boolean, accreditationFilter?: string, country?: string, withOrders?: boolean, withHoldings?: boolean, includeField?: Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerageFirmsAccountIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerageFirmAccountIndex(brokerageFirmId, page, size, search, createdAfter, lastAccessedAfter, accountType, accountStatus, assignedUserId, accountSponsorshipType, userStatus, organizationId, institutionalClient, backgroundCheckState, marketplaceAgreementSigned, accreditationFilter, country, withOrders, withHoldings, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves account statistics associated with the brokerage firm specified in the path
         * @param {number} brokerageFirmId 
         * @param {string} accountType 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerageFirmAccountStatisticShow(brokerageFirmId: number, accountType: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrokerageFirmsAccountsStatisticShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerageFirmAccountStatisticShow(brokerageFirmId, accountType, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves holding statistics associated with the event specified in query params
         * @param {number} eventId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holdingStatisticShow(eventId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoldingsStatisticShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holdingStatisticShow(eventId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves company statistics
         * @param {number} issuerEntityId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerEntityCompanyStatistics(issuerEntityId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuerEntitiesCompanyStatisticShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerEntityCompanyStatistics(issuerEntityId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new Second Market account from the information of an NPM Settlement account.
         * @param {number} accountId 
         * @param {AccountsSecondMarketAccountCopyCreateRequestContract} accountsSecondMarketAccountCopyCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secondMarketAccountCopyCreate(accountId: number, accountsSecondMarketAccountCopyCreateRequestContract: AccountsSecondMarketAccountCopyCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountShowAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secondMarketAccountCopyCreate(accountId, accountsSecondMarketAccountCopyCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary create/finish onboarding account
         * @param {AccountCreateRequestContract} accountCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountCreate(accountCreateRequestContract: AccountCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountShowAggregate> {
            return localVarFp.accountCreate(accountCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists all accounts
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {string} [lastAccessedAfter] 
         * @param {Array<string>} [accountType] 
         * @param {Array<string>} [userStatus] 
         * @param {number} [brokerageFirmId] 
         * @param {boolean} [hasActiveEvents] If set to true, accounts with at least one active event will be retuned. If set to false, accounts with no active events will be returned.
         * @param {boolean} [npmsOnly] filters accounts for NPMS brokerage firm
         * @param {string} [institutionalClient] 
         * @param {string} [backgroundCheckState] 
         * @param {boolean} [marketplaceAgreementSigned] filters whether account has signed marketplace agreement when specified
         * @param {string} [accreditationFilter] 
         * @param {Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountIndex(page?: number, size?: number, search?: string, lastAccessedAfter?: string, accountType?: Array<string>, userStatus?: Array<string>, brokerageFirmId?: number, hasActiveEvents?: boolean, npmsOnly?: boolean, institutionalClient?: string, backgroundCheckState?: string, marketplaceAgreementSigned?: boolean, accreditationFilter?: string, includeField?: Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountIndex> {
            return localVarFp.accountIndex(page, size, search, lastAccessedAfter, accountType, userStatus, brokerageFirmId, hasActiveEvents, npmsOnly, institutionalClient, backgroundCheckState, marketplaceAgreementSigned, accreditationFilter, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieves the details of an account
         * @param {string} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountShow(id: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountShowAggregate> {
            return localVarFp.accountShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates the account details
         * @param {number} id 
         * @param {AccountUpdateRequestContract} accountUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdate(id: number, accountUpdateRequestContract: AccountUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountShowAggregate> {
            return localVarFp.accountUpdate(id, accountUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create an accounts in the specified brokerage firm
         * @param {number} brokerageFirmId 
         * @param {BrokerageFirmsAccountCreateRequestContract} brokerageFirmsAccountCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmAccountCreate(brokerageFirmId: number, brokerageFirmsAccountCreateRequestContract: BrokerageFirmsAccountCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountForBrokerage> {
            return localVarFp.brokerageFirmAccountCreate(brokerageFirmId, brokerageFirmsAccountCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists all accounts in the specified brokerage firm
         * @param {number} brokerageFirmId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {string} [createdAfter] 
         * @param {string} [lastAccessedAfter] 
         * @param {Array<string>} [accountType] 
         * @param {Array<string>} [accountStatus] 
         * @param {Array<number>} [assignedUserId] 
         * @param {Array<string>} [accountSponsorshipType] 
         * @param {Array<string>} [userStatus] 
         * @param {number} [organizationId] 
         * @param {string} [institutionalClient] 
         * @param {string} [backgroundCheckState] 
         * @param {boolean} [marketplaceAgreementSigned] filters whether account has signed marketplace agreement when specified
         * @param {string} [accreditationFilter] 
         * @param {string} [country] 
         * @param {boolean} [withOrders] 
         * @param {boolean} [withHoldings] 
         * @param {Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmAccountIndex(brokerageFirmId: number, page?: number, size?: number, search?: string, createdAfter?: string, lastAccessedAfter?: string, accountType?: Array<string>, accountStatus?: Array<string>, assignedUserId?: Array<number>, accountSponsorshipType?: Array<string>, userStatus?: Array<string>, organizationId?: number, institutionalClient?: string, backgroundCheckState?: string, marketplaceAgreementSigned?: boolean, accreditationFilter?: string, country?: string, withOrders?: boolean, withHoldings?: boolean, includeField?: Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<BrokerageFirmsAccountIndex> {
            return localVarFp.brokerageFirmAccountIndex(brokerageFirmId, page, size, search, createdAfter, lastAccessedAfter, accountType, accountStatus, assignedUserId, accountSponsorshipType, userStatus, organizationId, institutionalClient, backgroundCheckState, marketplaceAgreementSigned, accreditationFilter, country, withOrders, withHoldings, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves account statistics associated with the brokerage firm specified in the path
         * @param {number} brokerageFirmId 
         * @param {string} accountType 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerageFirmAccountStatisticShow(brokerageFirmId: number, accountType: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<BrokerageFirmsAccountsStatisticShow> {
            return localVarFp.brokerageFirmAccountStatisticShow(brokerageFirmId, accountType, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves holding statistics associated with the event specified in query params
         * @param {number} eventId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holdingStatisticShow(eventId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<HoldingsStatisticShow> {
            return localVarFp.holdingStatisticShow(eventId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves company statistics
         * @param {number} issuerEntityId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityCompanyStatistics(issuerEntityId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<IssuerEntitiesCompanyStatisticShow> {
            return localVarFp.issuerEntityCompanyStatistics(issuerEntityId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new Second Market account from the information of an NPM Settlement account.
         * @param {number} accountId 
         * @param {AccountsSecondMarketAccountCopyCreateRequestContract} accountsSecondMarketAccountCopyCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondMarketAccountCopyCreate(accountId: number, accountsSecondMarketAccountCopyCreateRequestContract: AccountsSecondMarketAccountCopyCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountShowAggregate> {
            return localVarFp.secondMarketAccountCopyCreate(accountId, accountsSecondMarketAccountCopyCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accountCreate operation in AccountApi.
 * @export
 * @interface AccountApiAccountCreateRequest
 */
export interface AccountApiAccountCreateRequest {
    /**
     * 
     * @type {AccountCreateRequestContract}
     * @memberof AccountApiAccountCreate
     */
    readonly accountCreateRequestContract: AccountCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for accountIndex operation in AccountApi.
 * @export
 * @interface AccountApiAccountIndexRequest
 */
export interface AccountApiAccountIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountApiAccountIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AccountApiAccountIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountIndex
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountIndex
     */
    readonly lastAccessedAfter?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AccountApiAccountIndex
     */
    readonly accountType?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AccountApiAccountIndex
     */
    readonly userStatus?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof AccountApiAccountIndex
     */
    readonly brokerageFirmId?: number

    /**
     * If set to true, accounts with at least one active event will be retuned. If set to false, accounts with no active events will be returned.
     * @type {boolean}
     * @memberof AccountApiAccountIndex
     */
    readonly hasActiveEvents?: boolean

    /**
     * filters accounts for NPMS brokerage firm
     * @type {boolean}
     * @memberof AccountApiAccountIndex
     */
    readonly npmsOnly?: boolean

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountIndex
     */
    readonly institutionalClient?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountIndex
     */
    readonly backgroundCheckState?: string

    /**
     * filters whether account has signed marketplace agreement when specified
     * @type {boolean}
     * @memberof AccountApiAccountIndex
     */
    readonly marketplaceAgreementSigned?: boolean

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountIndex
     */
    readonly accreditationFilter?: string

    /**
     * 
     * @type {Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>}
     * @memberof AccountApiAccountIndex
     */
    readonly includeField?: Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for accountShow operation in AccountApi.
 * @export
 * @interface AccountApiAccountShowRequest
 */
export interface AccountApiAccountShowRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountShow
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for accountUpdate operation in AccountApi.
 * @export
 * @interface AccountApiAccountUpdateRequest
 */
export interface AccountApiAccountUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountApiAccountUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AccountUpdateRequestContract}
     * @memberof AccountApiAccountUpdate
     */
    readonly accountUpdateRequestContract: AccountUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiAccountUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for brokerageFirmAccountCreate operation in AccountApi.
 * @export
 * @interface AccountApiBrokerageFirmAccountCreateRequest
 */
export interface AccountApiBrokerageFirmAccountCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountApiBrokerageFirmAccountCreate
     */
    readonly brokerageFirmId: number

    /**
     * 
     * @type {BrokerageFirmsAccountCreateRequestContract}
     * @memberof AccountApiBrokerageFirmAccountCreate
     */
    readonly brokerageFirmsAccountCreateRequestContract: BrokerageFirmsAccountCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for brokerageFirmAccountIndex operation in AccountApi.
 * @export
 * @interface AccountApiBrokerageFirmAccountIndexRequest
 */
export interface AccountApiBrokerageFirmAccountIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly brokerageFirmId: number

    /**
     * 
     * @type {number}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly createdAfter?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly lastAccessedAfter?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly accountType?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly accountStatus?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly assignedUserId?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly accountSponsorshipType?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly userStatus?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly organizationId?: number

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly institutionalClient?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly backgroundCheckState?: string

    /**
     * filters whether account has signed marketplace agreement when specified
     * @type {boolean}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly marketplaceAgreementSigned?: boolean

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly accreditationFilter?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly country?: string

    /**
     * 
     * @type {boolean}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly withOrders?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly withHoldings?: boolean

    /**
     * 
     * @type {Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly includeField?: Array<'accreditation_level' | 'institutional_client' | 'last_transacted_at' | 'last_transacted_action' | 'secondmarket_order_count' | 'secondmarket_holding_count' | 'secondmarket_match_count' | 'secondmarket_order_count_issuer_entity' | 'secondmarket_holding_count_issuer_entity' | 'secondmarket_match_count_issuer_entity' | 'secondmarket_order_first_n_issuer_entity' | 'secondmarket_holding_first_n_issuer_entity' | 'secondmarket_match_first_n_issuer_entity'>

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for brokerageFirmAccountStatisticShow operation in AccountApi.
 * @export
 * @interface AccountApiBrokerageFirmAccountStatisticShowRequest
 */
export interface AccountApiBrokerageFirmAccountStatisticShowRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountApiBrokerageFirmAccountStatisticShow
     */
    readonly brokerageFirmId: number

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountStatisticShow
     */
    readonly accountType: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountStatisticShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountStatisticShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountStatisticShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountStatisticShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountStatisticShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiBrokerageFirmAccountStatisticShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for holdingStatisticShow operation in AccountApi.
 * @export
 * @interface AccountApiHoldingStatisticShowRequest
 */
export interface AccountApiHoldingStatisticShowRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountApiHoldingStatisticShow
     */
    readonly eventId: number

    /**
     * 
     * @type {string}
     * @memberof AccountApiHoldingStatisticShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiHoldingStatisticShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiHoldingStatisticShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiHoldingStatisticShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiHoldingStatisticShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiHoldingStatisticShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for issuerEntityCompanyStatistics operation in AccountApi.
 * @export
 * @interface AccountApiIssuerEntityCompanyStatisticsRequest
 */
export interface AccountApiIssuerEntityCompanyStatisticsRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountApiIssuerEntityCompanyStatistics
     */
    readonly issuerEntityId: number

    /**
     * 
     * @type {string}
     * @memberof AccountApiIssuerEntityCompanyStatistics
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiIssuerEntityCompanyStatistics
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiIssuerEntityCompanyStatistics
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiIssuerEntityCompanyStatistics
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiIssuerEntityCompanyStatistics
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiIssuerEntityCompanyStatistics
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for secondMarketAccountCopyCreate operation in AccountApi.
 * @export
 * @interface AccountApiSecondMarketAccountCopyCreateRequest
 */
export interface AccountApiSecondMarketAccountCopyCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountApiSecondMarketAccountCopyCreate
     */
    readonly accountId: number

    /**
     * 
     * @type {AccountsSecondMarketAccountCopyCreateRequestContract}
     * @memberof AccountApiSecondMarketAccountCopyCreate
     */
    readonly accountsSecondMarketAccountCopyCreateRequestContract: AccountsSecondMarketAccountCopyCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof AccountApiSecondMarketAccountCopyCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiSecondMarketAccountCopyCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiSecondMarketAccountCopyCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiSecondMarketAccountCopyCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiSecondMarketAccountCopyCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AccountApiSecondMarketAccountCopyCreate
     */
    readonly xUserRoleId?: string
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary create/finish onboarding account
     * @param {AccountApiAccountCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountCreate(requestParameters: AccountApiAccountCreateRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountCreate(requestParameters.accountCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists all accounts
     * @param {AccountApiAccountIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountIndex(requestParameters: AccountApiAccountIndexRequest = {}, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountIndex(requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.lastAccessedAfter, requestParameters.accountType, requestParameters.userStatus, requestParameters.brokerageFirmId, requestParameters.hasActiveEvents, requestParameters.npmsOnly, requestParameters.institutionalClient, requestParameters.backgroundCheckState, requestParameters.marketplaceAgreementSigned, requestParameters.accreditationFilter, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieves the details of an account
     * @param {AccountApiAccountShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountShow(requestParameters: AccountApiAccountShowRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountShow(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates the account details
     * @param {AccountApiAccountUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUpdate(requestParameters: AccountApiAccountUpdateRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountUpdate(requestParameters.id, requestParameters.accountUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create an accounts in the specified brokerage firm
     * @param {AccountApiBrokerageFirmAccountCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public brokerageFirmAccountCreate(requestParameters: AccountApiBrokerageFirmAccountCreateRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).brokerageFirmAccountCreate(requestParameters.brokerageFirmId, requestParameters.brokerageFirmsAccountCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists all accounts in the specified brokerage firm
     * @param {AccountApiBrokerageFirmAccountIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public brokerageFirmAccountIndex(requestParameters: AccountApiBrokerageFirmAccountIndexRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).brokerageFirmAccountIndex(requestParameters.brokerageFirmId, requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.createdAfter, requestParameters.lastAccessedAfter, requestParameters.accountType, requestParameters.accountStatus, requestParameters.assignedUserId, requestParameters.accountSponsorshipType, requestParameters.userStatus, requestParameters.organizationId, requestParameters.institutionalClient, requestParameters.backgroundCheckState, requestParameters.marketplaceAgreementSigned, requestParameters.accreditationFilter, requestParameters.country, requestParameters.withOrders, requestParameters.withHoldings, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves account statistics associated with the brokerage firm specified in the path
     * @param {AccountApiBrokerageFirmAccountStatisticShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public brokerageFirmAccountStatisticShow(requestParameters: AccountApiBrokerageFirmAccountStatisticShowRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).brokerageFirmAccountStatisticShow(requestParameters.brokerageFirmId, requestParameters.accountType, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves holding statistics associated with the event specified in query params
     * @param {AccountApiHoldingStatisticShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public holdingStatisticShow(requestParameters: AccountApiHoldingStatisticShowRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).holdingStatisticShow(requestParameters.eventId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves company statistics
     * @param {AccountApiIssuerEntityCompanyStatisticsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public issuerEntityCompanyStatistics(requestParameters: AccountApiIssuerEntityCompanyStatisticsRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).issuerEntityCompanyStatistics(requestParameters.issuerEntityId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new Second Market account from the information of an NPM Settlement account.
     * @param {AccountApiSecondMarketAccountCopyCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public secondMarketAccountCopyCreate(requestParameters: AccountApiSecondMarketAccountCopyCreateRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).secondMarketAccountCopyCreate(requestParameters.accountId, requestParameters.accountsSecondMarketAccountCopyCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AccountCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountCreateErrorCodes = [
    401,
    422,
];

export type AccountIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountIndexErrorCodes = [
    401,
    422,
];

export type AccountShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountShowErrorCodes = [
    401,
];

export type AccountUpdateErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountUpdateErrorCodes = [
    422,
];

export type BrokerageFirmAccountCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BrokerageFirmAccountCreateErrorCodes = [
    401,
    422,
];

export type BrokerageFirmAccountIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BrokerageFirmAccountIndexErrorCodes = [
    401,
    422,
];

export type BrokerageFirmAccountStatisticShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const BrokerageFirmAccountStatisticShowErrorCodes = [
    401,
    422,
];

export type HoldingStatisticShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const HoldingStatisticShowErrorCodes = [
    401,
];

export type IssuerEntityCompanyStatisticsErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerEntityCompanyStatisticsErrorCodes = [
    401,
];

export type SecondMarketAccountCopyCreateErrorTypes =

    | {
        status: 403;
        data: ErrorValidation;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecondMarketAccountCopyCreateErrorCodes = [
    403,
    422,
];


