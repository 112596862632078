import { Margin } from '@npm/core/ui/components/atoms/common';
import { InlineInputNumber } from '@npm/core/ui/components/atoms/Input';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import { HoldingsFormItem } from './HoldingsFormItem/HoldingsFormItem';
import { getCorrectionModeQuantityConfig } from './HoldingForm.utils';

import * as S from './HoldingForm.styles';

export const HoldingFormItemFilledQty = ({
  initialFilledQty,
  maxFilledQuantity,
}: {
  initialFilledQty?: number;
  maxFilledQuantity?: number;
}) => {
  return (
    <Margin top="md" bottom="xxxl">
      <HoldingsFormItem
        name="filled_qty"
        initialValue={initialFilledQty}
        label={
          <>
            <S.ColorIconWrapper name="arrows-opposite" size="md" color="info" />
            <Text weight="bold">Enter Filled Quantity</Text>
          </>
        }
        holdingItemConfigOverride={getCorrectionModeQuantityConfig({
          isCorrectionMode: false,
          min: 0,
          max: maxFilledQuantity,
        })}
      >
        <InlineInputNumber min={0} inputMode="decimal" />
      </HoldingsFormItem>
    </Margin>
  );
};
