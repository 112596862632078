/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  UserRoleApi,
  UserRoleIndexErrorCodes,
  UserRoleIndexErrorTypes,
  UserRoleCreateErrorCodes,
  UserRoleUpdateErrorCodes,
  UserRoleDestroyErrorCodes,
  UserRoleShowErrorCodes,
  UserRoleShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new UserRoleApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type UserRoleIndexMethod = UserRoleApi['userRoleIndex'];
export type UserRoleIndexResponseType = MethodResult<UserRoleIndexMethod>;

export const useUserRoleIndex = (
  variables: Parameters<UserRoleIndexMethod>[0],
  config?: UseQueryConfig<
    UserRoleIndexMethod,
    UserRoleIndexErrorTypes
  >
) => {
  return useQuery<
    UserRoleIndexMethod,
    UserRoleIndexErrorTypes
  >(
    (...args) => api.userRoleIndex(...args),
    UserRoleIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['UserRoleIndex'],
        ...apiQueryConfig.UserRoleIndex,
        ...config?.queryConfig
      },
    },
    'UserRoleIndex',
  );
};

export const useUserRoleIndexInfinite = (
  variables: Parameters<UserRoleIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    UserRoleIndexMethod,
    UserRoleIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    UserRoleIndexMethod,
    UserRoleIndexErrorTypes
  >(
    (...args) => api.userRoleIndex(...args),
    UserRoleIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['UserRoleIndex', 'UserRoleIndexInfinite'],
        ...apiQueryConfig.UserRoleIndex,
        ...config?.queryConfig,
      },
    },
    'UserRoleIndex',
  );
};

export const useUserRoleIndexLazy = (baseOptions?: {
  variables?: Parameters<UserRoleIndexMethod>[0],
  config?: UseQueryConfig<
    UserRoleIndexMethod,
    UserRoleIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    UserRoleIndexMethod,
    UserRoleIndexErrorTypes
  >(
    (...args) => api.userRoleIndex(...args),
    UserRoleIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['UserRoleIndex'],
        ...apiQueryConfig.UserRoleIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'UserRoleIndex',
  );
};


export type UserRoleCreateMethod = UserRoleApi['userRoleCreate'];

export const useUserRoleCreate = () => {
  return useMutation<UserRoleCreateMethod>(
    (...args) => api.userRoleCreate(...args),
    UserRoleCreateErrorCodes,
    'UserRoleCreate',
    '/api/user-roles',
    'post'
  );
};

export type UserRoleUpdateMethod = UserRoleApi['userRoleUpdate'];

export const useUserRoleUpdate = () => {
  return useMutation<UserRoleUpdateMethod>(
    (...args) => api.userRoleUpdate(...args),
    UserRoleUpdateErrorCodes,
    'UserRoleUpdate',
    '/api/user-roles',
    'put'
  );
};

export type UserRoleDestroyMethod = UserRoleApi['userRoleDestroy'];

export const useUserRoleDestroy = () => {
  return useMutation<UserRoleDestroyMethod>(
    (...args) => api.userRoleDestroy(...args),
    UserRoleDestroyErrorCodes,
    'UserRoleDestroy',
    '/api/user-roles',
    'delete'
  );
};

export type UserRoleShowMethod = UserRoleApi['userRoleShow'];
export type UserRoleShowResponseType = MethodResult<UserRoleShowMethod>;

export const useUserRoleShow = (
  variables: Parameters<UserRoleShowMethod>[0],
  config?: UseQueryConfig<
    UserRoleShowMethod,
    UserRoleShowErrorTypes
  >
) => {
  return useQuery<
    UserRoleShowMethod,
    UserRoleShowErrorTypes
  >(
    (...args) => api.userRoleShow(...args),
    UserRoleShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['UserRoleShow'],
        ...apiQueryConfig.UserRoleShow,
        ...config?.queryConfig
      },
    },
    'UserRoleShow',
  );
};

export const useUserRoleShowInfinite = (
  variables: Parameters<UserRoleShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    UserRoleShowMethod,
    UserRoleShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    UserRoleShowMethod,
    UserRoleShowErrorTypes
  >(
    (...args) => api.userRoleShow(...args),
    UserRoleShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['UserRoleShow', 'UserRoleShowInfinite'],
        ...apiQueryConfig.UserRoleShow,
        ...config?.queryConfig,
      },
    },
    'UserRoleShow',
  );
};

export const useUserRoleShowLazy = (baseOptions?: {
  variables?: Parameters<UserRoleShowMethod>[0],
  config?: UseQueryConfig<
    UserRoleShowMethod,
    UserRoleShowErrorTypes
  >
}) => {
  return useLazyQuery<
    UserRoleShowMethod,
    UserRoleShowErrorTypes
  >(
    (...args) => api.userRoleShow(...args),
    UserRoleShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['UserRoleShow'],
        ...apiQueryConfig.UserRoleShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'UserRoleShow',
  );
};

