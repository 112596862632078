import type { ReactNode } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import { ZendeskContactButton } from '../../app/zendesk';

type Props = {
  issuerEntityName?: string;
  message?: ReactNode;
  description?: ReactNode;
};

export const MissingPoOAlert = ({
  issuerEntityName,
  message = 'Missing Proof of Ownership',
  description,
}: Props) => (
  <Alert
    type="warning"
    icon={<Icon name="info-circle" size="sm" />}
    message={message}
    description={
      description ?? (
        <Text size={'sm'}>
          You can find your Certificates, Options Grants, and/or Awards by
          logging in to your cap-table management tool. We will then review your
          holdings prior to posting your offer
          {issuerEntityName ? `to ${issuerEntityName} market. ` : '.'} Feel free
          to{' '}
          <ZendeskContactButton
            zendeskTicketGroupId={'online_submission_progress'}
          >
            contact us
          </ZendeskContactButton>{' '}
          if you have any questions.
        </Text>
      )
    }
    expandable={false}
  />
);
