import React from 'react';
import { useTheme } from 'styled-components';

import { type CbLabelProps, type CbRenderElement } from '@npm/utils';

import { Icon } from '../Icon';
import { Text } from '../Typography';

import { Label } from './Label';

export const CbLabel = (props: CbLabelProps): CbRenderElement => {
  const theme = useTheme();

  if (!props) return null;

  const {
    variant = 'general',
    iconProps,
    label,
    round,
    dashed,
    style,
    microPadding,
  } = props;

  if (!label) return <Text size="sm">--</Text>;

  const icon = iconProps ? <Icon {...iconProps} /> : undefined;

  return (
    <Label
      round={round}
      icon={icon}
      variant={variant}
      dashed={dashed}
      style={{
        ...style,
        padding: microPadding ? theme.spacing.xs : undefined,
      }}
    >
      {label}
    </Label>
  );
};
