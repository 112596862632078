import { type AxiosInstance } from 'axios';

import { eventBus } from '@npm/core/ui/utils/eventBus';

import { NDA_URL_STORAGE_KEY } from './nda.constants';

const NDA_URL_ROUTE = '/investor-workstation/programs/live-programs/' as const;

const NDA_URL = `${window.location.origin}${NDA_URL_ROUTE}`;

const HTTP_CODE = 442;

export const initNDAHandling = (axios: AxiosInstance) => {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      if (error?.response?.status === HTTP_CODE) {
        const EVENT_ID = error?.response?.data?.event_id;
        const URL = NDA_URL + EVENT_ID + '/sign-nda';

        if (EVENT_ID && !window.location.href.includes(URL)) {
          const params = window.location.href.split('?')[1];

          window.localStorage.setItem(
            NDA_URL_STORAGE_KEY,
            window.location.pathname + window.location.search
          );
          if (
            !eventBus.dispatch('HISTORY_EVENT', {
              type: 'replace',
              location:
                '/investor-workstation/programs/live-programs/' +
                EVENT_ID +
                `/sign-nda?${params}`,
            })
          ) {
            window.location.href = URL;
          }
        }
      }

      return Promise.reject(error);
    }
  );
};
