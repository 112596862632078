import Form from 'antd/lib/form';

import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import {
  type BrokerageFirmAggregate,
  type IssuerEntityAggregate,
  type VisibilityCode,
  CbATSPool,
  CbVisibility,
  Codebooks,
  useCodebook,
} from '@npm/data-access';

import { usePermissions } from '../../../../../../auth/permissions/usePermissions';
import { ATSPoolSelect } from '../../../../components/inputs/ATSPoolSelect';
import { type VisibilityFormSectionFields } from '../../../../components/inputs/types';
import {
  getVisibilityPreview,
  handleExpirationDateResetOnVisibilityChange,
} from '../../../../components/inputs/utils';
import {
  validateVisibilityOption,
  VisibilitySelect,
} from '../../../../components/inputs/VisibilitySelect';
import { canSelectATSPool } from '../../../../utils/order';

type Props = {
  value: VisibilityFormSectionFields;
  initialValue?: string;
  hidden?: boolean;
  orderType: string | undefined;
  brokerageFirm: BrokerageFirmAggregate | undefined;
  key: string;
  issuer?: IssuerEntityAggregate;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const VisibilityFormSection = ({
  value,
  initialValue,
  orderType,
  brokerageFirm,
  hidden,
  key,
  issuer,
  ...props
}: Props) => {
  const form = Form.useFormInstance<VisibilityFormSectionFields>();

  const { data: atsPoolData } = useCodebook({
    type: Codebooks.ATS_POOL,
  });

  const { BRO_CHANGE_ORDER_VISIBILITY } = usePermissions();

  const handleVisibilityChange = (val: VisibilityCode) => {
    handleExpirationDateResetOnVisibilityChange(form, val, value.expiration);

    form.setFieldsValue({
      atsPool:
        val === CbVisibility.items.internal
          ? CbATSPool.items.internal
          : CbATSPool.items.ats_sm,
    });
  };

  return (
    <CollapsibleFormSection.Item
      label="Visibility"
      preview={getVisibilityPreview(
        atsPoolData?.codebooks ?? [],
        value.atsPool
      )}
      style={hidden ? { display: 'none' } : {}}
      key={key}
      name={key}
      {...props}
    >
      <FormItem
        name="visibility"
        marginBottom="sm"
        rules={[VALIDATION_RULES.required()]}
        hidden={hidden}
      >
        <VisibilitySelect
          onChange={handleVisibilityChange}
          validateOption={option =>
            validateVisibilityOption(
              option,
              orderType,
              brokerageFirm,
              BRO_CHANGE_ORDER_VISIBILITY,
              issuer
            )
          }
        />
      </FormItem>
      <FormItem
        name="atsPool"
        marginBottom="sm"
        rules={[VALIDATION_RULES.required()]}
        hidden={
          hidden ||
          !canSelectATSPool(value.visibility as VisibilityCode, brokerageFirm)
        }
      >
        <ATSPoolSelect
          visibility={value.visibility as VisibilityCode}
          brokerageFirm={brokerageFirm}
        />
      </FormItem>
    </CollapsibleFormSection.Item>
  );
};
