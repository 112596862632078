import { CbHoldingState } from '@npm/data-access';

export const getModalConfig = (
  certificate_number: string | undefined,
  type: 'reject' | 'revert' | 'verify' | 'pre-verify'
) => {
  if (type === 'revert') {
    return {
      title: 'Undo Holding Rejection Action',
      description:
        'Are you sure you want to revert this holding to a pending status? The correction instructions provided will be deleted and you will be able to verify the holding.',
      confirm: 'Undo Holding Rejection',
    };
  }

  if (type === 'reject') {
    return {
      title: `Reject holding ${certificate_number ?? ''}`,
      description: (
        <>
          Tell the seller what is missing and/or incorrect with this holding and
          they will update accordingly. Note that taking this action will not
          cancel the transaction(s).
          <br />
          <br />
          Please provide correction instructions for this holding below.
        </>
      ),
      confirm: 'Reject',
    };
  }

  if (type === 'verify') {
    return {
      title: `Verify holding ${certificate_number ?? ''}`,
      description:
        'Are you sure you want to verify ownership of this holding? This action cannot be undone.',
      confirm: 'Verify',
    };
  }
  if (type === 'pre-verify') {
    return {
      title: `Pre-verify holding ${certificate_number ?? ''}`,
      description:
        'Are you sure you want to pre-verify ownership of this holding? This action cannot be undone.',
      confirm: 'Pre-verify',
    };
  }

  return {};
};

export const getNewHoldingState = (
  type: 'reject' | 'revert' | 'verify' | 'pre-verify'
) => {
  if (type === 'revert') return CbHoldingState.items.pending;
  if (type === 'reject') return CbHoldingState.items.needs_verification;
  if (type === 'verify') return CbHoldingState.items.verified;
  if (type === 'pre-verify') return CbHoldingState.items.pre_verified;
};
