import { useMemo } from 'react';

import { useIssuerEntityShow } from '@npm/data-access';

import { useUserRole } from '../context/userRole.context';

export const useCurrentIssuer = (loadIssuer = false) => {
  const userRole = useUserRole();

  const issuerId =
    userRole?.workstationType === 'issuer'
      ? userRole?.subRole?.subject?.id
      : undefined;

  const { data: issuer, isLoading } = useIssuerEntityShow(
    {
      id: issuerId?.toString(),
    },
    {
      queryConfig: {
        enabled: !!issuerId && loadIssuer,
      },
    }
  );

  return useMemo(() => {
    return {
      issuerId,
      issuer,
    };
  }, [issuerId, issuer, isLoading]);
};
