import styled from 'styled-components';

export const CorrectionInstructionsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md}px;

  & > .ant-form {
    padding-top: ${({ theme }) => theme.spacing.md}px;
    border-top: 1px solid ${({ theme }) => theme.color.general.borders.primary};
    .ant-row:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.md}px;
    }
  }
`;
