import { Flex } from '@npm/core/ui/components/atoms/common';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import type { IssuerEntityAggregate } from '@npm/data-access';

type Props = {
  title?: React.ReactNode;
  id?: number;
  withCompanyLogo: boolean;
  issuerEntity?: IssuerEntityAggregate;
};

export const DrawerTitle = ({
  title,
  id,
  withCompanyLogo,
  issuerEntity,
}: Props) => {
  const defaultTitle = id ? 'Edit Holding' : 'Add Holding';

  return withCompanyLogo ? (
    <Flex justify="space-between" style={{ width: '100%' }} align="center">
      {title || defaultTitle}
      <CompanyLogo
        size={'md'}
        name={issuerEntity?.name}
        url={issuerEntity?.logo_url}
      />
    </Flex>
  ) : (
    <>{title || defaultTitle}</>
  );
};
