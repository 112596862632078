import styled from 'styled-components';

import { Alert } from '../Alert';

export const RuleAlert = styled(Alert)`
  display: inline-flex;
  width: auto;
  padding: ${({ theme }) => `${theme.spacing.xs}px ${theme.spacing.sm}px`};
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;
`;
