/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  PaymentInformationApi,
  AccountPaymentInformationCreateErrorCodes,
  AccountPaymentInformationIndexErrorCodes,
  AccountPaymentInformationIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new PaymentInformationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountPaymentInformationCreateMethod = PaymentInformationApi['accountPaymentInformationCreate'];

export const useAccountPaymentInformationCreate = () => {
  return useMutation<AccountPaymentInformationCreateMethod>(
    (...args) => api.accountPaymentInformationCreate(...args),
    AccountPaymentInformationCreateErrorCodes,
    'AccountPaymentInformationCreate',
    '/api/accounts/{account_id}/payment-informations',
    'post'
  );
};

export type AccountPaymentInformationIndexMethod = PaymentInformationApi['accountPaymentInformationIndex'];
export type AccountPaymentInformationIndexResponseType = MethodResult<AccountPaymentInformationIndexMethod>;

export const useAccountPaymentInformationIndex = (
  variables: Parameters<AccountPaymentInformationIndexMethod>[0],
  config?: UseQueryConfig<
    AccountPaymentInformationIndexMethod,
    AccountPaymentInformationIndexErrorTypes
  >
) => {
  return useQuery<
    AccountPaymentInformationIndexMethod,
    AccountPaymentInformationIndexErrorTypes
  >(
    (...args) => api.accountPaymentInformationIndex(...args),
    AccountPaymentInformationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountPaymentInformationIndex'],
        ...apiQueryConfig.AccountPaymentInformationIndex,
        ...config?.queryConfig
      },
    },
    'AccountPaymentInformationIndex',
  );
};

export const useAccountPaymentInformationIndexInfinite = (
  variables: Parameters<AccountPaymentInformationIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountPaymentInformationIndexMethod,
    AccountPaymentInformationIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountPaymentInformationIndexMethod,
    AccountPaymentInformationIndexErrorTypes
  >(
    (...args) => api.accountPaymentInformationIndex(...args),
    AccountPaymentInformationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountPaymentInformationIndex', 'AccountPaymentInformationIndexInfinite'],
        ...apiQueryConfig.AccountPaymentInformationIndex,
        ...config?.queryConfig,
      },
    },
    'AccountPaymentInformationIndex',
  );
};

export const useAccountPaymentInformationIndexLazy = (baseOptions?: {
  variables?: Parameters<AccountPaymentInformationIndexMethod>[0],
  config?: UseQueryConfig<
    AccountPaymentInformationIndexMethod,
    AccountPaymentInformationIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountPaymentInformationIndexMethod,
    AccountPaymentInformationIndexErrorTypes
  >(
    (...args) => api.accountPaymentInformationIndex(...args),
    AccountPaymentInformationIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountPaymentInformationIndex'],
        ...apiQueryConfig.AccountPaymentInformationIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountPaymentInformationIndex',
  );
};

