import React, { useMemo } from 'react';

import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { Radio } from '@npm/core/ui/components/atoms/Radio';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CbRoleType, useCodebook, useCodebookPost } from '@npm/data-access';

import { getCbUserAccountRoleTypeOptions } from './UserRolesFormItem.utils';

import * as S from '../Form.styles';

type Props = {
  name?: string;
  label?: React.ReactNode;
  initialValue?: string;
  withLabel?: boolean;
  includeLimitedAccessRole?: boolean;
  includeLimitedAccessSignerRole?: boolean;
};

export const UserRolesFormItem = ({
  name = 'role',
  label = 'Role',
  initialValue = CbRoleType.items.ADMIN_AND_SIGNER,
  withLabel = true,
  includeLimitedAccessRole = true,
  includeLimitedAccessSignerRole = true,
}: Props) => {
  const { data, isLoading } = useCodebookPost({
    codebookIndexRequestContract: {
      type: CbRoleType.code,
      attribute: [
        {
          name: 'scope',
          value: 'account',
        },
      ],
    },
  });

  const userRoles = useMemo(() => {
    if (!data) return [];
    return getCbUserAccountRoleTypeOptions(data?.codebooks);
  }, [data]);

  return (
    <Skeleton.Base loading={isLoading}>
      <FormItem
        name={name}
        label={withLabel ? label : null}
        labelPosition="top"
        initialValue={initialValue}
      >
        <S.RadioGroup>
          {userRoles.map(userRole => {
            if (
              !includeLimitedAccessRole &&
              userRole.value === CbRoleType.items.ACCOUNT_GUEST
            ) {
              return null;
            }

            if (
              !includeLimitedAccessSignerRole &&
              userRole.value === CbRoleType.items.GUEST_AND_SIGNER
            ) {
              return null;
            }

            return (
              <Radio
                key={userRole.label}
                label={userRole.label}
                value={userRole.value}
              >
                <Text size="sm" colorVariant="primary" marginBottom="xs">
                  {userRole.label}
                </Text>
                <Text size="xs">{userRole.description}</Text>
              </Radio>
            );
          })}
        </S.RadioGroup>
      </FormItem>
    </Skeleton.Base>
  );
};
