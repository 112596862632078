import { isEqual, sortBy } from 'lodash';

export function isInViewport(rect: DOMRect) {
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function getErrorFieldsNames(error: {
  errorFields?: { name?: string[] }[];
}) {
  return (() => {
    try {
      return error.errorFields?.map(eF => eF?.name?.[0]);
    } catch {
      return [];
    }
  })();
}

export function getErrorFieldsApiNames(error: {
  data: {
    error_fields?: { field_name?: string }[];
  };
}) {
  return (() => {
    try {
      return error?.data.error_fields?.map(eF => eF?.field_name);
    } catch {
      return [];
    }
  })();
}

export const hasOnlySpecificProperties = (
  obj: Record<string, unknown>,
  properties: string[]
) => {
  const objProperties = Object.keys(obj);

  if (objProperties.length !== properties.length) {
    return false;
  }

  return isEqual(sortBy(objProperties), sortBy(properties));
};
